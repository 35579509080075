import permissionTypes from '../../src/js/util/permission-types.js'

const mockAssets = [
  {
    name: 'Tezos',
    symbol: 'XTZ',
    quantity: 11,
  },
  {
    name: 'Bitcoin',
    symbol: 'BTC',
    quantity: 1,
  },
  {
    name: 'Cardano',
    symbol: 'ADA',
    quantity: 2,
  },
]

const mockTransactions = [
  {
    date: '9/18/2021',
    description: 'Deposit wire transfer',
    credit: 100,
    debit: 0,
  },
  {
    date: '9/15/2021',
    description: 'Deposit wire transfer',
    credit: 500000,
    debit: 0,
  },
  {
    date: '9/16/2021',
    description: 'Deposit wire transfer',
    credit: 0,
    debit: 20000,
  },
  { date: '9/17/2021', description: '2% trading fee', credit: 0, debit: 20000 },
]

const mockUser1 = {
  userId: '123e4567-e89b-12d3-a456-426614174000',
  accountNumber: '123456567',
  firstName: 'Jude',
  lastName: 'Hay',
  email: 'jude@beatles.com',
  kind: permissionTypes.ADMIN,
  approved: true,
  assets: mockAssets,
  transactions: mockTransactions,
}
const mockSessionUser1 = {
  userId: '123e4567-e89b-12d3-a456-426614174000',
  kind: permissionTypes.ADMIN,
}
const mockUser2 = {
  userId: '123e4567-e89b-12d3-a456-426614174222',
  accountNumber: '1222345',
  firstName: 'James',
  lastName: 'Hoffman',
  email: 'james@gmail.com',
  kind: permissionTypes.CLIENT,
  assets: mockAssets,
  approved: true,
  transactions: [],
}
const mockUser3 = {
  userId: '123e4567-e89b-12d3-a456-426614174111',
  accountNumber: '8657309',
  firstName: 'Sarah',
  lastName: 'Banella',
  email: 'sarah@banellas.com',
  kind: permissionTypes.CLIENT,
  assets: [],
  approved: false,
  transactions: mockTransactions,
}

export const mockUsers = [mockUser1, mockUser2, mockUser3]
export const mockSessionUsers = [mockSessionUser1]
