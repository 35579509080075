// @ts-check

import { go } from '../router/router'
import { routes } from '../router/routes'
import { deleteSessionUser, getSessionExpiration } from './session'
import { getRouteByPathname } from '../router/router-util'
import paths from '../router/paths'
export default class SessionTimer {
  /**
   * @description starts timer that checks session every 45 seconds
   */
  static startSessionTimeout() {
    this.checkTimeout()
    this.session = setInterval(this.checkTimeout.bind(this), 45000)
  }

  static isSessionExpired() {
    const expiry = new Date(getSessionExpiration()).getTime()
    const now = new Date().getTime()

    return expiry < now
  }

  static checkTimeout() {
    const viewObj = getRouteByPathname(routes, window.location.pathname)
    if (!viewObj || viewObj.isPublic) return

    if (this.isSessionExpired()) {
      clearInterval(this.session)
      deleteSessionUser()
      return go(paths.LOGIN, { timeout: true })
    }
  }
}
