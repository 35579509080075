// @ts-check

import { setSessionData, setUserToken } from '../../session/session.js'
import { post } from '../../services/app-fetch.js'
import { DEFAULT_API } from '../../services/api-paths.js'
import { getUserDefaultRoute } from '../../router/router-util.js'
import { routes } from '../../router/routes.js'
import { getUserById } from '../../services/user.service.js'

/**
 * @typedef {import('../../models/user.model').User} User
 * @typedef {import('../../services/login.service').UserLoginData} UserLoginData
 */

/**
 * @typedef {Object} LoginResult
 * @property {User} user
 * @property {string} path - Redirect here after login attempt
 * @property {object} params - query params to include
 */

/**
 * @param {UserLoginData} args
 * @returns {Promise<LoginResult>}
 */
export async function loginUser({ email, password }) {
  try {
    const res = await post(`${DEFAULT_API}/v1/authentication/token`, {
      email,
      password,
    })

    if (res.status === 200) {
      const { userId, token, expiry } = await res.json()
      setUserToken(token)

      const user = await getUser(userId)

      if (user === null) {
        setUserToken(null)
        return {
          user: null,
          path: '/login',
          params: { approved: false },
        }
      } else {
        setSessionData({ expiry, user })
        return { user, path: defaultPath(user), params: {} }
      }
    } else if (res.status === 401 || res.status === 403) {
      return {
        user: null,
        path: '/login',
        params: { failed: true, invalidCredentials: true, email },
      }
    } else {
      return { user: null, path: '/login', params: { failed: true, email } }
    }
  } catch (err) {
    return { user: null, path: '/login', params: { failed: true, email } }
  }
}

/**
 * @param {User} user
 * @returns {string}
 */
function defaultPath(user) {
  return getUserDefaultRoute(routes, user).path
}

/**
 * @param {string} userId
 * @returns {Promise<User>}
 */
async function getUser(userId) {
  try {
    const res = await getUserById(userId, false)
    // @ts-ignore
    if (res.ok) return await res.json()
    else return null
  } catch (err) {
    return null
  }
}
