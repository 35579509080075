import { LitElement, html, css } from 'lit'

class VBIconsEditElement extends LitElement {
  static properties = {
    size: {
      type: String,
      reflect: true,
    },
  }

  constructor() {
    super()

    this.size = 'medium'
  }

  static styles = css`
    :host {
      display: inline-block;
      width: var(--vb-icon-size);
      height: var(--vb-icon-size);
      cursor: pointer;
    }
    svg {
      width: 100%;
      height: 100%;
      fill: var(--icon-fill);
    }
    :host([size='medium']) {
      --vb-icon-size: 24px;
    }
    :host([size='large']) {
      --vb-icon-size: 32px;
    }
  `

  render() {
    return html`
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 216 216"
        style="enable-background:new 0 0 216 216;"
        xml:space="preserve"
      >
        <!-- <style type="text/css">
          .st0{fill:#1C352C;}
          .st1{fill:#1C352C;stroke:#1C352C;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
          .st2{fill:#FFFFFF;}
          .st3{fill:none;stroke:#1C352C;stroke-width:6;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
        </style> -->
        <g>
          <path
            class="st0"
            d="M154.98,80.46C154.98,80.46,154.98,80.46,154.98,80.46c-0.93,5.46-4.29,9.33-8.08,13.09
            c-17.93,17.79-35.73,35.7-53.63,53.52c-0.92,0.91-2.28,1.59-3.55,1.89c-7.38,1.76-21.58,4.87-26.98,5.87
            c-1.03,0.19-1.91-0.74-1.68-1.76c2.02-8.86,4.02-17.72,6.14-26.55c0.33-1.39,1.12-2.87,2.12-3.88c18.46-18.57,37-37.06,55.47-55.61
            c4.95-4.97,10.72-7.07,17.54-5.17c6.89,1.92,10.98,6.62,12.36,13.65c0.05,0.26,0.19,0.5,0.29,0.76c0,0,0,0,0,0.01L154.98,80.46z
            M138.63,95.42c-5.98-5.9-12.06-11.9-17.75-17.51c-0.01-0.01-0.02-0.01-0.03,0c-15.1,15.11-30.33,30.36-45.47,45.52
            c-0.01,0.01,0,0.03,0.01,0.03c3.37,0,7.05,0.02,10.74,0c6.87-0.03,6.6-0.41,6.55,6.45c-0.02,3.29-0.01,6.58,0.01,9.88
            c0,0.49,0.12,0.98,0.21,1.69c0,0.02,0.02,0.02,0.03,0.01c15.4-15.51,30.57-30.8,45.69-46.04
            C138.63,95.44,138.63,95.43,138.63,95.42z M142.15,92.21c1.61-1.62,3.25-3.17,4.79-4.8c3.98-4.25,4.9-10.64,1.94-15.65
            c-2.94-4.98-8.77-7.4-14.35-5.93c-4.75,1.25-7.44,5.11-10.57,8.13c-0.01,0.01-0.01,0.02,0,0.03c6.11,6.13,12.06,12.11,18.16,18.22
            C142.13,92.21,142.14,92.21,142.15,92.21z M73.31,148.02c0,0.01,0.01,0.02,0.02,0.02c4.88-1.14,9.48-2.17,14.05-3.34
            c0.46-0.12,1-1.06,1.02-1.63c0.1-3.28,0.05-6.57,0.05-9.85c0-1.78,0-3.55,0-5.49c0-0.01-0.01-0.02-0.02-0.02
            c-5.5,0-10.65-0.02-15.8,0.04c-0.41,0-1.08,0.47-1.17,0.84c-1.14,4.64-2.21,9.3-3.36,14.25c0,0.01,0.01,0.02,0.02,0.02l5.17,0
            c0.01,0,0.02,0.01,0.02,0.02L73.31,148.02z"
          />
        </g>
      </svg>
    `
  }
}

customElements.define('vb-icons-edit', VBIconsEditElement)
export default VBIconsEditElement
