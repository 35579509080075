// @ts-check

/**
 * @typedef {import('../../js/models/router.model.js').Route} Route
 */

/**
 * @typedef NavItem
 * @type {object}
 * @property {string} label
 * @property {string} path
 * @property {string} [featureFlag]
 */

import { LitElement, html, css } from 'lit'

import '../../design-system/icons/md-icon'
import '../nav/router-link'
import { getSessionUser } from '../../js/session/session.js'
import { isAdmin } from '../../js/util/permissons.js'
import { featureFlags } from '../../../build/feature-flags'

class NavBarElement extends LitElement {
  static properties = {
    currentItemRegex: { type: RegExp },
    _items: { type: Array, state: true },
  }

  constructor() {
    super()

    /** @type {RegExp} */
    this.currentItemRegex = null

    /** @type {NavItem[]} */
    this._items = []
  }

  connectedCallback() {
    super.connectedCallback()

    const user = getSessionUser()

    if (isAdmin(user)) {
      // Nav Items visible to Admin users
      this._items = [
        {
          label: 'Admin',
          path: '/admin',
        },
        {
          label: 'Users',
          path: '/users',
        },
      ]
    } else {
      // Nav items visible to all other users
      this._items = [
        {
          label: 'Account Summary',
          path: '/account-summary',
        },
        {
          label: 'Profile',
          path: '/profile',
        },
        {
          label: 'Documents',
          path: '/documents/view',
        },
      ]
    }
  }

  render() {
    return html`
      <nav>
        <ul>
          ${this._items.map((item) =>
            featureFlags[item.featureFlag] || !item.featureFlag
              ? html`
                  <li>
                    <router-link
                      to=${item.path}
                      selected=${this.currentItemRegex.test(item.path)}
                    >
                      ${item.label}
                    </router-link>
                  </li>
                `
              : null
          )}
        </ul>
        <router-link to="/login" class="logout"
          ><md-icon icon="logout"></md-icon
        ></router-link>
      </nav>
    `
  }

  static styles = css`
    :host {
      width: 100%;
      box-sizing: border-box;
    }

    nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      font-size: 0.85rem;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      height: 100%;
    }

    md-icon {
      --icon-size: var(--icon-size-sm);
      font-size: 1rem;
    }

    a,
    ::part(a) {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      text-align: center;
      color: var(--cloud);
      padding: 1rem;
      cursor: pointer;
      text-decoration: none;
    }

    a:hover,
    ::part(a):hover {
      background-color: var(--accent);
    }

    a[selected='true'],
    router-link[selected='true']::part(a) {
      border-bottom: solid 1px var(--accent);
    }

    @media only screen and (min-width: 1024px) {
      nav {
        font-size: 1rem;
      }
      md-icon {
        --icon-size: var(--icon-size-md);
      }
    }
  `
}

customElements.define('nav-bar', NavBarElement)
export default NavBarElement
