// @ts-check
/**
 * @typedef {Object} PermissionTypes
 * @property {'Administrator'} ADMIN superuser
 * @property {'Client'} CLIENT average client of vb
 * */

/** @type {PermissionTypes} */
const permissionTypes = {
  ADMIN: 'Administrator',
  CLIENT: 'Client',
}

export default permissionTypes
