import {
  getTransactionsReport,
  updateTransactionsReport,
  createTransactionsReport,
} from '../services/report.service'
import {
  convertTransactionTableToDbValues,
  getTransactionTableData,
} from '../util/reports/transaction-report'

export async function getTransactionsTableData(userId, getEmptyConfig) {
  const { transactionsReport } = getEmptyConfig
    ? { transactionsReport: [] }
    : await getTransactionsReport(userId)

  return getTransactionTableData(transactionsReport?.transactionItems || [])
}

export async function updateTransactionsTableData(userId, transactionsReport) {
  const formattedForDb = convertTransactionTableToDbValues(transactionsReport)
  return await updateTransactionsReport(userId, formattedForDb)
}

export async function createTransactionsTableData(userId) {
  return await createTransactionsReport(userId, [])
}
