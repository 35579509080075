import { DEFAULT_API } from '../../../src/js/services/api-paths.js'
import { mockUsers } from '../user.mocks.js'

const endpoints = {
  [`${DEFAULT_API}/users`]: mockUsers,
}

// Add mock users
mockUsers.forEach((user) => {
  endpoints[`${DEFAULT_API}/users/${user.userId}`] = user
})

export default endpoints
