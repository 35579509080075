// @ts-check

/**
 * @type {{
 *  LOGIN: 'login',
 *  CREATE_USER: 'create-user',
 *  LOGOUT: 'logout',
 *  USER_UPDATED: 'user-updated',
 *  REFRESH_USER: 'refresh-user',
 *  TOAST: 'toast',
 *  URL_UPDATED: 'url-updated',
 * }}
 * */

const AppEvents = {
  LOGIN: 'login',
  CREATE_USER: 'create-user',
  USER_UPDATED: 'user-updated',
  REFRESH_USER: 'refresh-user',
  LOGOUT: 'logout',
  TOAST: 'toast',
  URL_UPDATED: 'url-updated',
}

export default AppEvents
