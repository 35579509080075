// @ts-check

/** @typedef {import('../../../js/models/accounts.model').Account} Account */
/** @typedef {import('../../../js/models/accounts.model').AccountTypes} AccountTypes */
/** @typedef {import('../../../js/models/accounts.model').Transaction} Transaction */

import { LitElement, html, css } from 'lit'
import { ViewContainerMixin } from '../../../js/mixins/view-container-mixin.js'

import '../../shared/reports/report-header'
import { toast } from '../../../js/util/toast.js'

import { getAccountsByUserId } from '../../../js/services/primaryApi'
import { dollarsToDisplay } from '../../../js/util/reports/report-util.js'

// Render Helpers

/** @param {string} timestamp */
function formatDate(timestamp) {
  return new Date(timestamp).toLocaleDateString()
}

/** @param {AccountTypes} acctType */
function formatAccountType(acctType) {
  switch (acctType) {
    case 'high_yield_savings_account':
      return 'High Yield Savings Account'
    default:
      return ''
  }
}

/** @param {Transaction[]} transactions */
function renderTransactions(transactions) {
  if (transactions) {
    const balance = (() => {
      const totalCredits = transactions
        .map((t) => t.credit)
        .reduce((p, c) => p + c, 0)

      const totalDebits = transactions
        .map((t) => t.debit)
        .reduce((p, c) => p + c, 0)

      // initial balance is included as a credit transaction
      return totalCredits - totalDebits
    })()

    return html`
      <table>
        <caption>
          <h3>Transactions</h3>
        </caption>

        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Description</th>
            <th scope="col">Credit</th>
            <th scope="col">Debit</th>
          </tr>
        </thead>

        <tbody>
          ${transactions.map(
            ({ description, date, credit, debit }) => html`<tr>
              <td>${new Date(date).toLocaleDateString()}</td>
              <td>${description}</td>
              <td>${dollarsToDisplay(credit)}</td>
              <td>${dollarsToDisplay(debit)}</td>
            </tr>`
          )}
        </tbody>

        <tfoot>
          <tr class="balance-row">
            <td></td>
            <td>Current Balance</td>
            <td colspan="2">${dollarsToDisplay(balance)}</td>
          </tr>
        </tfoot>
      </table>
    `
  } else {
    return html`<p class="info">No transactions</p>`
  }
}

// Component

class AccountReportsContainer extends ViewContainerMixin(LitElement) {
  static properties = {
    _accounts: { state: true },
  }

  constructor() {
    super()

    /** @type {Account[]} */
    this._accounts = []
  }

  async connectedCallback() {
    super.connectedCallback()

    // Get reports
    try {
      this._accounts = await getAccountsByUserId(this.user.userId)
    } catch (err) {
      toast('There was a problem fetching account data')
    }

    // Add paged media styles for print formatting
    const style = document.createElement('style')
    const pagedMediaStyles = css`
      @page {
        margin: 0.5in;
      }
    `
    document.head.appendChild(style)
    style.innerHTML = pagedMediaStyles.toString()
  }

  render() {
    return html`
      <div class="container">
        <report-header .user=${this.user}></report-header>

        ${this._accounts.map((account) => {
          const {
            name,
            accountType,
            accountId,
            attributes: {
              initialDate,
              maturationDate,
              initialValue,
              maturationValue,
              interestRate,
            },
            transactions,
          } = account

          return html`
            <article class="report">
              <header>
                <div class="name-id">
                  <h2>${name}</h2>
                  <p class="acct-no">#${accountId}</p>
                </div>
                <p>${formatAccountType(accountType)}</p>
              </header>

              <div class="report-meta">
                <p>Initial Date: <span>${formatDate(initialDate)}</span></p>
                <p>
                  Maturation Date:
                  <span>${formatDate(maturationDate)}</span>
                </p>
                <p>
                  Initial Value: <span>${dollarsToDisplay(initialValue)}</span>
                </p>
                <p>
                  Maturation Value:
                  <span>${dollarsToDisplay(maturationValue)}</span>
                </p>
                <p>Interest Rate: <span>${interestRate * 100}%</span></p>
              </div>

              <div class="report-transactions">
                ${renderTransactions(transactions)}
              </div>

              <footer>
                <p>Report generated ${new Date().toLocaleString()}</p>
              </footer>
            </article>
          `
        })}
      </div>
    `
  }

  static styles = css`
    :host {
      display: block;
      margin: 0;
      padding: 0;
    }

    h1,
    h2,
    h3,
    p,
    table {
      margin: 0;
    }

    h2,
    h3 {
      font-family: var(--headline-font);
      color: var(--range);
    }

    h3 {
      text-align: left;
      margin: 0.5rem 0;
      font-size: 12pt;
    }

    .container {
      box-sizing: border-box;
      width: 8.5in;
      padding: 0.5in;
      color: var(--primary);
      font-family: var(--primary-font);
    }

    .report {
      margin: 2rem 0 1in;
      break-after: page;
    }

    header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: 2px solid var(--cloud);
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
    }

    .name-id {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .acct-no {
      color: var(--font-light);
      font-style: italic;
      font-size: 10pt;
      position: relative;
      top: 0.3rem;
    }

    .report-meta {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0.5rem 0;
      font-size: 10pt;
      margin-bottom: 2rem;
    }

    .report-meta p {
      display: flex;
      justify-content: space-between;
      max-width: 15rem;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      background: #f8f8f8;
      font-size: 10pt;
    }

    td,
    th {
      padding: 0.5rem 1rem;
      text-align: left;
      background: transparent;
    }

    thead {
      background: var(--cloud);
    }

    tbody tr:nth-of-type(even) {
      background: #eee;
    }

    tfoot {
      border-top: 1px solid var(--cloud);
    }

    tfoot td:last-of-type {
      text-align: center;
    }

    footer p {
      margin: 1rem;
      text-align: center;
      font-size: 10pt;
      font-style: italic;
    }

    .info {
      text-align: center;
      padding: 0.5rem 1rem;
      background: var(--cloud);
    }

    @media print {
      .container {
        margin: 0;
        padding: 0;
        width: 100%;
      }
    }
  `
}

customElements.define('account-reports-container', AccountReportsContainer)
export default AccountReportsContainer
