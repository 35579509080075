// @ts-check

import { isEqual } from '../is-equal'
import { dollarsToDisplay, assetPriceToDollarDisplay } from './report-util'
import { copy } from '../copy'

export const ASSET_REPORT_ID = 'ASSET-REPORT'

export const ASSET_HEADER_ROW = ['Coin Name', 'Symbol', 'QTY', 'Price', 'Value']
export const ASSET_ROW_SYMBOL_INDEX = 1
export const ASSET_ROW_QUANTITY_INDEX = 2
export const ASSET_ROW_PRICE_INDEX = 3
export const ASSET_ROW_VALUE_INDEX = 4

const FOOTER_TOTAL_TEXT = 'Total'

/**
 * @typedef {import('../../models/assetReport.model').AssetReportTableDataRow} DataRow
 * @typedef {import('../../models/assetReport.model').AssetReportTableHeaderRow} HeaderRow
 * @typedef {import('../../models/assetReport.model').AssetReportTableFooterRow} FooterRow
 * @typedef {import('../../models/reports.model').ReportDisplayConfig} DisplayConfig
 * @typedef {import('../../models/crypto.model').CryptoPrice} CryptoPrice
 * */

/** * @type {import('../../../components/design-system/app-editable-table/app-editable-table.js').AppEditTableControl} */
export const tableControl = {
  dataRows: [{}, {}, {}, {}, { readOnly: true }],
}

/**
 * @param {DataRow[]} tableData an array of table data rows ['Cardano', 'ADA', 2, 2.43]
 * @returns {CryptoPrice[]} array of objects formatted for the database { name: 'Cardano', symbol: 'ADA', quantity: 2 price: 2.43 }
 */
export function convertAssetsToDbValues(tableData = []) {
  return tableData.filter(_isDataRow).map(([name, symbol, quantity]) => ({
    name,
    symbol,
    quantity,
  }))
}

export function _isFooterRow(/** @type {HeaderRow | DataRow | FooterRow} */ r) {
  return r.some((cell) => cell === FOOTER_TOTAL_TEXT)
}

/** @returns {boolean} */
export function _isEmptyRow(/** @type {HeaderRow | DataRow | FooterRow} */ r) {
  let isEmptyRow = true

  r.forEach((cell) => {
    if (cell !== '') isEmptyRow = false
  })

  return isEmptyRow
}

export function _isHeaderRow(/** @type {HeaderRow | DataRow | FooterRow} */ r) {
  return isEqual(r, ASSET_HEADER_ROW)
}

export function _isDataRow(/** @type {HeaderRow | DataRow | FooterRow} */ r) {
  return !_isHeaderRow(r) && !_isFooterRow(r) && !_isEmptyRow(r)
}

/**
 * @param {Array<HeaderRow|DataRow|FooterRow>} tableData an array of table header, data, and footer rows
 * @returns {Array<*>} add money display values ['Cardano', 'ADA', 2, '$2.43'] and total in footer row
 */
export function formatAssetTableDataForDisplay(tableData) {
  // Shallow copy to avoid data mutation
  const copiedTableData = copy(tableData)
  const headerRowEnd = 1
  const footerRowStart = copiedTableData.length - 1

  /** @type {HeaderRow} */
  const [headerRow] = copiedTableData
  /** @type {DataRow[]} */
  const dataRows = copiedTableData.slice(headerRowEnd, footerRowStart) || []
  /** @type {FooterRow}*/
  const footerRow = copiedTableData[footerRowStart]

  return [
    headerRow,
    ..._formatAssetsForDisplay(dataRows.sort(_sortByAssetTotal)),
    _formatFooterRowForDisplay(footerRow),
  ]
}

/**
 *
 * @param {DataRow} a
 * @param {DataRow} b
 * @returns
 */
export function _sortByAssetTotal(a, b) {
  return +b[ASSET_ROW_VALUE_INDEX] - +a[ASSET_ROW_VALUE_INDEX]
}

/**
 * @param {DataRow[]} dataRows
 * @returns {Array<string[]>}
 * */
export function _formatAssetsForDisplay(dataRows) {
  /**
   * Copy the data rows to avoid mutation and recast the type to avoid type errors
   * @type {Array<string[]>}
   * */
  const dataRowsCopy = copy(dataRows)

  return dataRowsCopy.map((a, i) => {
    a[ASSET_ROW_PRICE_INDEX] = assetPriceToDollarDisplay(
      dataRows[i][ASSET_ROW_PRICE_INDEX]
    )
    a[ASSET_ROW_VALUE_INDEX] = dollarsToDisplay(
      dataRows[i][ASSET_ROW_VALUE_INDEX]
    )

    return a
  })
}

/**
 *  @param {FooterRow} footerRow
 *  TODO: This is a hack to get the footer row to display correctly. The footer row should be a separate type
 *  @returns {Array<string[]>} add money display values to footer row
 * */
export function _formatFooterRowForDisplay(footerRow) {
  const newFooterRow = copy(footerRow)

  newFooterRow[ASSET_ROW_VALUE_INDEX] = dollarsToDisplay(
    footerRow[ASSET_ROW_VALUE_INDEX]
  )

  return newFooterRow
}

/**
 * @param {import('../../models/accounts.model.js').AccountV2} account user assets returned from the database
 * @returns {DisplayConfig}
 */
export function getAssetTableData(account) {
  const parsedAssets = account.cryptoAssets
    // TODO: depreciate function after handling error state for printed reports
    .map(_setNullPriceToZero)
    .map(_parseDbAssetRow)
    .sort(_sortByAssetTotal)

  return {
    // id used for identifying account clicked to edit by user
    id: `${ASSET_REPORT_ID}_${account.id}`,
    tableData: [
      ASSET_HEADER_ROW,
      ...parsedAssets,
      _getCalculatedFooterRow(parsedAssets),
    ],
    displayFormatter: formatAssetTableDataForDisplay,
    hasData: !!parsedAssets.length,
    reportName: account.name,
    tableControl,
  }
}

/**
 * @param {DataRow[]} assets array of cyrpto asset response data
 * @returns {FooterRow} Footer row, including the calculated total
 */
export function _getCalculatedFooterRow(assets) {
  const total = assets.reduce((acc, a) => acc + +a[ASSET_ROW_VALUE_INDEX], 0)

  return ['', FOOTER_TOTAL_TEXT, '', '', total]
}

/**
 * @param {CryptoPrice} arg
 * @returns {DataRow}
 * */
export function _parseDbAssetRow({ name, symbol, quantity, price }) {
  const totalValue = +quantity * +price

  return [name, symbol, quantity, price, totalValue]
}

// TODO: depreciate function after handling error state for printed reports
/**
 * @param {CryptoPrice} asset
 * @returns {CryptoPrice}
 * */
export function _setNullPriceToZero(asset) {
  return { ...asset, price: asset.price ?? 0 }
}
