// @ts-check

import permissionTypes from '../util/permission-types'
import { isAdmin, isClient } from '../util/permissons.js'
import paths from './paths.js'
import { pathToRegexp, match } from 'path-to-regexp'
import {
  convertSnakeToDash,
  convertSnakeToSentence,
} from '../util/string-methods'
import { featureFlags } from '../../../build/feature-flags'

/**
 * @typedef {import('./paths').Paths} Paths
 * @typedef {import('../models/router.model.js').Route} Route
 * @typedef {import('../models/router.model.js').RouteIds} RouteIds
 * @typedef {import('../models/router.model.js').RouteConfig} RouteConfig
 */

/** @type {RouteIds} */
export const routeIds = {
  BASE_ROUTE: 'BASE_ROUTE',
  ADMIN: 'ADMIN',
  ADMIN_USER: 'ADMIN_USER',
  CREATE_ACCOUNT: 'CREATE_ACCOUNT',
  HIGH_YIELD_SAVINGS_SIGNUP: 'HIGH_YIELD_SAVINGS_SIGNUP',
  USER_DOCUMENTS_VIEW: 'USER_DOCUMENTS_VIEW',
  USER_DOCUMENTS_CREATE: 'USER_DOCUMENTS_CREATE',
  USERS: 'USERS',
  USER_ACCOUNT_SUMMARY: 'USER_ACCOUNT_SUMMARY',
  USER_PROFILE: 'USER_PROFILE',
  USER_ALERTS: 'USER_ALERTS',
  LOGIN: 'LOGIN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  NEW_PASSWORD: 'NEW_PASSWORD',
  PORTFOLIO_REPORT: 'PORTFOLIO_REPORT',
  ACCOUNT_REPORTS: 'ACCOUNT_REPORTS',
  WORK_WITH_US: 'WORK_WITH_US',
  NOT_FOUND: 'NOT_FOUND',
}

/** @type {RouteConfig[]} */
export const configObjects = [
  {
    id: routeIds.ADMIN,
    permissions: [isAdmin],
  },
  {
    id: routeIds.ADMIN_USER,
    title: 'User',
    permissions: [isAdmin],
  },
  {
    id: routeIds.HIGH_YIELD_SAVINGS_SIGNUP,
    title: 'Savings Signup',
    permissions: [isClient],
    featureFlag: 'FF_HIGH_YIELD_SAVINGS_SIGNUP',
  },
  {
    id: routeIds.USER_DOCUMENTS_VIEW,
    permissions: [isClient, isAdmin],
    hideNavBar: false,
    title: 'Documents',
  },
  {
    id: routeIds.USER_DOCUMENTS_CREATE,
    permissions: [isClient],
    hideNavBar: false,
    title: 'New Document',
  },
  {
    id: routeIds.LOGIN,
    isPublic: true,
    hideNavBar: true,
  },
  {
    id: routeIds.USERS,
    defaultFor: permissionTypes.ADMIN,
    permissions: [isAdmin],
  },
  {
    id: routeIds.CREATE_ACCOUNT,
    isPublic: true,
    hideNavBar: true,
  },
  {
    id: routeIds.RESET_PASSWORD,
    isPublic: true,
    hideNavBar: true,
  },
  {
    id: routeIds.NEW_PASSWORD,
    isPublic: true,
    hideNavBar: true,
  },
  {
    id: routeIds.WORK_WITH_US,
    isPublic: true,
    hideNavBar: true,
  },
  {
    id: routeIds.USER_ACCOUNT_SUMMARY,
    defaultFor: permissionTypes.CLIENT,
    title: 'Account Summary',
  },
  {
    id: routeIds.USER_PROFILE,
    permissions: [isClient],
    hideNavBar: false,
    title: 'Profile',
  },
  {
    id: routeIds.USER_ALERTS,
    permissions: [isClient],
    hideNavBar: false,
    title: 'Alerts',
  },
  {
    id: routeIds.PORTFOLIO_REPORT,
    manualImport: true,
    permissions: [isClient, isAdmin],
    hideNavBar: true,
  },
  {
    id: routeIds.ACCOUNT_REPORTS,
    manualImport: true,
    permissions: [isClient, isAdmin],
    hideNavBar: true,
  },

  {
    id: routeIds.NOT_FOUND,
    permissions: [isClient, isAdmin],
    hideNavBar: false,
  },
]

const routeConfigs = configObjects.filter(
  ({ featureFlag }) => !featureFlag || featureFlags[featureFlag]
)

/**
 * @param {Paths} paths
 * @param {RouteConfig} routeConfig
 * @returns {Route}
 */
export function buildRoute(paths, routeConfig) {
  let {
    title,
    id,
    hideNavBar = false,
    component,
    componentPath,
    permissions = [],
    isPublic = false,
    defaultFor,
    inheritedProperties = [],
  } = routeConfig

  const path = paths[id]
  if (!path)
    console.error(
      'No path found for route id: ',
      id,
      'Please add a corresponding path the paths.js'
    )

  // Dynamically set title, component, and component path using id
  const dashedId = convertSnakeToDash(id)
  title = title || convertSnakeToSentence(id)
  component = component || `${dashedId}-container`
  componentPath = componentPath || `${dashedId}/${component}`

  return {
    id,
    title,
    component,
    componentPath,
    defaultFor,
    path,
    hideNavBar,
    isPublic,
    inheritedProperties,
    permissions,
    pathRegex: pathToRegexp(path),
    urlMatch: match(path, { decode: decodeURIComponent }),
  }
}

const routes = routeConfigs.map((config) => buildRoute(paths, config))

export { routes }
