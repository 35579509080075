// @ts-check

import { copy } from '../copy'
import { dollarsToDisplay } from './report-util'
import { dateToDbFormat, getSimpleDate } from '../date'
import { sortByDate } from '../sort'

export const TRANSACTION_REPORT_ID = 'TRANSACTION-REPORT'

/**
 * @typedef {Object} EditTableControlCell
 * @property {boolean} [readOnly] makes the edit cell read only
 * @property {string} [placeholder] adds a placeholder to the field for clarity of input format
 */

/**
 * @typedef {string[]} TransactionDisplayRow
 * @typedef {[string, string, number, number, number, number]} TransactionDataRow
 * @typedef {['', 'Monthly Account Value', '', '', '', number]} TransactionFooterDataRow
 * @typedef {['', 'Monthly Account Value', '', '', '', string]} TransactionFooterDisplayRow
 * @typedef {{date: string, description: string, credit: number, debit: number}} DatabaseTransaction
 * @typedef {Array<TransactionHeaderRow|TransactionDataRow|TransactionFooterDataRow>} TransactionsTableData
 * @typedef {['Date', 'Description', 'Credit', 'Debit', 'Principal Balance', 'Account Balance']} TransactionHeaderRow
 * @typedef {Array<TransactionDisplayRow|TransactionFooterDisplayRow|TransactionHeaderRow>} TransactionsDisplayData
 */

/** @type {TransactionHeaderRow} */
export const TRANSACTION_HEADER_ROW = [
  'Date',
  'Description',
  'Credit',
  'Debit',
  'Principal Balance',
  'Account Balance',
]

/**
 * @typedef TableData
 * @type {Array<Array<string|number>>}
 * @property {TransactionHeaderRow} TransactionHeaderRow
 * @property {DataRow} DataRow
 */

const DATE_INDEX = 0
const CREDIT_INDEX = 2
const DEBIT_INDEX = 3
const BALANCE_INDEX = 4
export const FOOTER_ACCOUNT_BALANCE_INDEX = 5

const HEADER_ROW_END_INDEX = 1
const FOOTER_ROW_LENGTH = 1

/**
 * @param {TransactionsTableData} data
 * @returns {TransactionsTableData} specifically just the data rows
 */
const extractDataRows = (data) =>
  data.slice(HEADER_ROW_END_INDEX, data.length - FOOTER_ROW_LENGTH)

export const tableControl = {
  dataRows: [
    { placeholder: 'DD/MM/YYYY' },
    {},
    {},
    {},
    { readOnly: true },
    { readOnly: true },
  ],
}

/**
 * @param {DatabaseTransaction} row
 * @returns {DatabaseTransaction}
 */
const formatDateToDb = (row) => ({ ...row, date: dateToDbFormat(row.date) })

/**
 * @param {DatabaseTransaction} row
 * @returns {DatabaseTransaction}
 * */
export const ensureZeroValues_ = (row) => {
  if (!row.credit) row.credit = 0
  if (!row.debit) row.debit = 0

  return row
}

/**
 *
 * @param {TransactionDataRow} row
 * @returns {DatabaseTransaction}
 */
export const formatDataRowToObj_ = ([date, description, credit, debit]) => ({
  date,
  description,
  credit,
  debit,
})

/**
 * @param {TransactionsTableData} data
 * @returns {DatabaseTransaction[]}
 * */
export const convertTransactionTableToDbValues = (data) =>
  extractDataRows(data)
    .map(formatDataRowToObj_)
    .map(ensureZeroValues_)
    .map(formatDateToDb)

/**
 * @param {TransactionDataRow[]} transactions
 * @returns {TransactionDisplayRow[]}
 */
export const formatTransactionsForDisplay = (transactions) =>
  transactions.map((r) => [
    String(r[0]),
    String(r[1]),
    dollarsToDisplay(r[CREDIT_INDEX]),
    dollarsToDisplay(r[DEBIT_INDEX]),
    dollarsToDisplay(r[BALANCE_INDEX]),
    '',
  ])

/**
 *
 * @param {DatabaseTransaction[]} a
 * @returns {import('../../models/reports.model').ReportDisplayConfig}
 */
export function getTransactionTableData(a = []) {
  const transactions = parseDbTransactions(copy(a))

  return {
    id: TRANSACTION_REPORT_ID,
    tableData: [
      TRANSACTION_HEADER_ROW,
      ...transactions,
      getCalculatedFooterDataRow(transactions),
    ],
    reportName: 'Transactions',
    tableControl,
    displayFormatter: formatTransactionTableDataForDisplay,
    hasData: !!transactions?.length,
  }
}

/**
 * @param {Array<*>} tableData
 * @returns {TransactionsDisplayData}
 */
export function formatTransactionTableDataForDisplay(tableData) {
  const footerRowStart = tableData.length - FOOTER_ROW_LENGTH
  /** @type {TransactionDataRow[]} */
  const dataRows = tableData.slice(HEADER_ROW_END_INDEX, footerRowStart)
  /** @type {TransactionFooterDataRow} */
  const footerRow = tableData[footerRowStart]

  return [
    TRANSACTION_HEADER_ROW,
    ...formatTransactionsForDisplay(dataRows),
    getFooterDisplayRow(+footerRow[FOOTER_ACCOUNT_BALANCE_INDEX]),
  ]
}

/**
 * @param {TransactionDataRow[]} transactions
 * @returns {TransactionFooterDataRow}
 * */
const getCalculatedFooterDataRow = (transactions) => {
  const lastDataRow = transactions[transactions.length - FOOTER_ROW_LENGTH]
  const lastDataRowBalance = (lastDataRow && lastDataRow[BALANCE_INDEX]) || 0

  return ['', 'Monthly Account Value', '', '', '', lastDataRowBalance]
}

/**
 * @param {number} balance
 * @returns {TransactionFooterDisplayRow}
 * */
const getFooterDisplayRow = (balance) => [
  '',
  'Monthly Account Value',
  '',
  '',
  '',
  `${dollarsToDisplay(balance)}`,
]

/** @param {DatabaseTransaction} dataObj */
const parseDbTransactionRow = ({ date, description, credit, debit }) => [
  date,
  description,
  credit,
  debit,
  0,
]

/**
 * @param {TransactionDataRow} row
 * @returns {TransactionDataRow}
 * */
const formatRowDate = (row) => {
  row[DATE_INDEX] = getSimpleDate(row[DATE_INDEX])

  return row
}

/**
 * @param {DatabaseTransaction[]} transactions
 * @returns {TransactionDataRow[]}
 * */
export const parseDbTransactions = (transactions) =>
  transactions
    .sort(sortByDate)
    .map(parseDbTransactionRow)
    .map(formatRowDate)
    .map(calculateBalanceColumns.bind(transactions))

/**
 * @param {TransactionDataRow} row
 * @param {number} i
 * @param {TransactionDataRow[]} allTransactionRows
 * @returns {TransactionDataRow} row with calculated balance column
 */
const calculateBalanceColumns = (row, i, allTransactionRows) => {
  const previousRow = allTransactionRows[i - 1]
  const previousRowTotal = (previousRow && previousRow[BALANCE_INDEX]) || 0
  const currentRowTotal = +row[CREDIT_INDEX] - +row[DEBIT_INDEX]

  row[BALANCE_INDEX] = previousRowTotal + currentRowTotal

  return row
}
