import { LitElement, html, css } from 'lit'

import '../../design-system/logos/vb-logo'

class AppHeader extends LitElement {
  render() {
    return html`
      <header>
        <vb-logo class="logo"></vb-logo>
        <slot name="nav"></slot>
      </header>
    `
  }

  static styles = css`
    header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1rem;
      --base-padding: 0.25rem;
      padding: var(--base-padding);
      padding-top: calc(var(--base-padding) + env(safe-area-inset-top, 2rem));
      background: var(--range);
      color: var(--cloud);
      box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.4);
    }

    .logo {
      height: 1.75rem;
      margin: 0.5rem;
    }

    ::slotted(*) {
      padding: 0;
      margin: 0;
      background: transparent;
    }

    @media (min-width: 768px) {
      header {
        --base-padding: 0.4rem;
      }

      .logo {
        height: 2.5rem;
        margin: 0.5rem 1rem;
      }
    }

    @media (min-width: 1024px) {
      header {
        --base-padding: 0.5rem;
        gap: 2rem;
      }

      .logo {
        height: 3.5rem;
        margin: 0.75rem 1.5rem;
      }
    }
  `
}

customElements.define('app-header', AppHeader)
export default AppHeader
