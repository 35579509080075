import { LitElement, html, css } from 'lit'
import { ViewContainerMixin } from '../../../js/mixins/view-container-mixin.js'

import './client-report'

import { toast } from '../../../js/util/toast.js'
import { getCurrentCryptoPrices } from '../../../js/services/primaryApi.js'
import { getUserAccounts } from '../../../js/services/accountsv2.service.js'
import { getAssetTableData } from '../../../js/util/reports/asset-report.js'
import { getTransactionsTableData } from '../../../js/controllers/report.controller.js'
import {
  isCryptoAssetAccount,
  updateCryptoAssetPrices,
} from '../user-account-summary/accounts-util.js'

class PortfolioReportContainer extends ViewContainerMixin(LitElement) {
  static properties = {
    loading: { type: Boolean },
    reports: { type: Array },
  }

  constructor() {
    super()

    this.reports = []
    this.loading = false
  }

  connectedCallback() {
    super.connectedCallback()

    this.initReports_(this.user.userId)

    const style = document.createElement('style')
    const pagedMediaStyles = css`
      @page {
        margin: 0.5in;
      }
    `

    document.head.appendChild(style)
    style.innerHTML = pagedMediaStyles.toString()
  }

  async initReports_(userId) {
    try {
      const transactionsReport = await getTransactionsTableData(userId)

      const cryptoAssetsWithPrices = await updateCryptoAssetPrices(
        ((await getUserAccounts(userId)) || []).filter(isCryptoAssetAccount),
        await getCurrentCryptoPrices()
      ).map(getAssetTableData)

      this.reports = [transactionsReport, ...cryptoAssetsWithPrices]
    } catch (error) {
      toast('There was a problem getting reports for this user.')
    }
  }

  render() {
    return this.reports.map(
      (report) => html`<client-report .user=${this.user} .report=${report} />`
    )
  }

  static styles = css`
    client-report {
      break-after: page;
      margin: 2rem auto;
    }

    client-report:last-of-type {
      break-after: auto;
    }

    @media print {
      client-report {
        margin: 0;
      }
    }
  `
}

customElements.define('portfolio-report-container', PortfolioReportContainer)
export default PortfolioReportContainer
