// @ts-check

/** @typedef {import('../../../js/models/user.model').User} User */

import { LitElement, html, css } from 'lit'

import '../../../design-system/logos/vb-logo'

export class ReportHeader extends LitElement {
  static properties = {
    user: { type: Object },
  }

  constructor() {
    super()

    /** @type {User} */
    this.user = null
  }

  render() {
    const userName = `${this.user.lastName}, ${this.user.firstName}`
    const address = this.user.address
      ? html` ${this.user.address}
          ${this.user.address2 ? html`<br />${this.user.address2}` : null}
          <br />
          ${this.user.city}, ${this.user.state} ${this.user.zip}
          <br />${this.user.country}`
      : null
    return html`
      <header>
        <h1>
          <vb-logo></vb-logo>
        </h1>
        <div class="user-info">
          <div class="user-heading">
            <h2 class="name">${userName}</h2>
            <p class="account-number">#${this.user.accountNumber}</p>
          </div>
          <div class="contact">
            <p>${address}</p>
          </div>
        </div>
      </header>
    `
  }

  static styles = css`
    :host {
      display: block;
    }

    header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    h1,
    h2 {
      font-family: var(--headline-font);
      margin: 0;
    }

    p {
      margin: 0;
    }

    vb-logo {
      width: 250px;
      height: auto;
      position: relative;
      top: 0.75rem;
      color: var(--range);
    }

    .user-info {
      font-size: 10pt;
    }

    .user-heading {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .account-number {
      color: var(--font-light);
      position: relative;
      top: 0.3rem;
    }

    .user-info .name {
      font-size: 20pt;
      font-weight: 400;
      color: var(--range);
    }

    .contact {
      margin: 0;
      font-size: 9pt;
    }
  `
}

customElements.define('report-header', ReportHeader)
