// @ts-check
/**
 * @typedef {{
 * BASE_ROUTE: '/',
 * CREATE_ACCOUNT: '/create-account',
 * HIGH_YIELD_SAVINGS_SIGNUP: '/high-yield-savings-signup',
 * ADMIN: '/admin',
 * ADMIN_USER: '/users/:userId',
 * USER_ACCOUNT_SUMMARY: '/account-summary',
 * USER_PROFILE: '/profile',
 * USER_ALERTS: '/alerts',
 * USER_DOCUMENTS_VIEW: '/documents/(view)?',
 * USER_DOCUMENTS_CREATE: '/documents/new/:newDoc',
 * USERS: '/users',
 * LOGIN: '/login',
 * RESET_PASSWORD: '/reset-password',
 * NEW_PASSWORD: '/new-password',
 * PORTFOLIO_REPORT: '/users/:userId/portfolio-report',
 * ACCOUNT_REPORTS: '/users/:userId/account-reports',
 * WORK_WITH_US: '/work-with-us',
 * NOT_FOUND: '/not-found'
 * } & Object<string,string>} Paths
 */

/** @type {Paths} */
const paths = {
  ACCOUNT_REPORTS: '/users/:userId/account-reports',
  ADMIN: '/admin',
  ADMIN_USER: '/users/:userId',
  BASE_ROUTE: '/',
  CREATE_ACCOUNT: '/create-account',
  HIGH_YIELD_SAVINGS_SIGNUP: '/high-yield-savings-signup',
  LOGIN: '/login',
  NEW_PASSWORD: '/new-password',
  NOT_FOUND: '/not-found',
  PORTFOLIO_REPORT: '/users/:userId/portfolio-report',
  RESET_PASSWORD: '/reset-password',
  USER_ACCOUNT_SUMMARY: '/account-summary',
  USER_PROFILE: '/profile',
  USER_ALERTS: '/alerts',
  USER_DOCUMENTS_VIEW: '/documents/(view)?',
  USER_DOCUMENTS_CREATE: '/documents/new/:newDoc',
  USERS: '/users',
  WORK_WITH_US: '/work-with-us',
}

export default paths
