// Please consult docs for standards on time
// https://github.com/The-Veteran-Broker-LLC/docs/blob/main/standards/time.md

/**
 * @param {String|Date} date the date including the desired month. If no argument is passed in, it defaults to the current month.
 * @returns {String} MM/01/YYYY
 */
export function startOfMonthSimpleDate(date = Date.now()) {
  const d = new Date(date)

  return `${d.getMonth() + 1}/1/${d.getFullYear()}`
}

/**
 * @param {String|Date} date the date including the desired day/month/year. If not argument is passed in, it defaults to the current day.
 * @returns {String} MM/DD/YYYY
 */
export function getSimpleDate(date = Date.now()) {
  const d = new Date(date)
  return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
}

/**
 * @param {number|string} n
 * @returns {string} the two digit value (prefixed with a 0 if n is less than 10)
 */
export const ensureTwoDigit = (n) => (+n < 10 ? `0${n}` : `${n}`)

/**
 * @param {string} date any date
 * @returns the normalized date set to noon. Per documentation, uses Rfc3339 format. (e.g. 2020-01-01T12:00:00.000Z)
 */
export function dateToDbFormat(date) {
  const d = new Date(date)
  d.setUTCHours(12, 0, 0, 0)

  return d.toISOString()
}
