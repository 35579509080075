import { getSessionUser, deleteSessionUser } from '../session/session.js'
import AppEvents from '../app-events.js'
import { loginUser } from '../util/login/login.js'
import { getUserById } from '../services/user.service.js'
import { go } from '../router/router.js'

export function loginMixin(SuperClass) {
  class Login extends SuperClass {
    static properties = {
      user: { type: Object },
    }

    constructor() {
      super()

      this.fetchLoggedInUser()

      this.addEventListener(AppEvents.LOGIN, this.login.bind(this))
      this.addEventListener(AppEvents.LOGOUT, this.logout.bind(this))
      this.addEventListener(AppEvents.USER_UPDATED, this.userUpdated.bind(this))
    }

    async fetchLoggedInUser() {
      try {
        const sessionUser = getSessionUser()
        if (sessionUser) {
          this.user = await getUserById(sessionUser.userId)
        } else {
          this.user = {}
        }
      } catch (err) {
        this.user = {}
      }
    }

    userUpdated({ detail }) {
      // If detail.user is empty or undefined, we shouldn't do anything
      if (!detail.user?.userId) return

      const viewContainer = this.shadowRoot.querySelector('#view-container')

      // If we updated the currently logged in user, replace this.user with
      // the new user record.
      if (detail.user.userId === this.user.userId) {
        this.user = detail.user
      }

      // If we updated the user used by the view container, replace
      // viewContainer.user with the new user record
      if (viewContainer && detail.user.userId === viewContainer.user.userId) {
        viewContainer.user = detail.user
      }
    }

    async login({ detail: { email, password } }) {
      const { user, path, params } = await loginUser({ email, password })

      this.user = user || {}

      go(path, params)
    }

    logout() {
      this.user = {}
      deleteSessionUser()
    }
  }

  return Login
}
