import { LitElement, html, css } from 'lit'

class MdIconElement extends LitElement {
  static properties = {
    icon: { type: String },
  }

  constructor() {
    super()
    this.icon = ''
  }
  render() {
    return html`<span class="material-icons">${this.icon}</span>`
  }

  static styles = css`
    :host {
      display: inline-block;
      height: var(--icon-size, 24px);
      width: var(--icon-size, 24px);
    }

    .material-icons {
      font-family: 'Material Icons';
      font-weight: normal;
      font-style: normal;
      font-size: var(--icon-size, 24px);
      display: inline-block;
      line-height: 1;
      text-transform: none;
      letter-spacing: normal;
      word-wrap: normal;
      white-space: nowrap;
      direction: ltr;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      -moz-osx-font-smoothing: grayscale;
      font-feature-settings: 'liga';
    }
  `
}

customElements.define('md-icon', MdIconElement)
export default MdIconElement
