// @ts-check

/**
 * @typedef {import('../models/user.model').User} User
 * @typedef {import('../models/router.model.js').Route} Route
 * @typedef {import('../models/router.model.js').ContextParams} ContextParams
 */

import paths from './paths'
import { routes } from './routes'
import { getSessionUser } from '../session/session'
import {
  appendParamsObjToPath,
  decodeQuerystringValues,
  getUserDefaultRoute,
  getRouteByPathname,
  routeIsAllowed,
} from './router-util'
import AppEvents from '../app-events'

/**
 * @param {string} path
 * @param {Object<string,*>} [params]
 * */
export function go(path, params) {
  // Only go to the route if a matching route component exists
  if (getRouteByPathname(routes, path)) {
    window.history.pushState(null, null, appendParamsObjToPath(path, params))
    onRouteChanged()
  }
}

/**
 * @param {function} [redirect] override function used for redirection in the event that a route is not allowed.
 */
export function onRouteChanged(redirect = go) {
  const { pathname, search } = window.location
  const user = getSessionUser()
  const nextView = getRouteByPathname(routes, pathname)

  const redirectPath = user
    ? getUserDefaultRoute(routes, user)?.path
    : paths.LOGIN

  if (pathname === paths.BASE_ROUTE) return redirect(redirectPath)
  if (!nextView) return redirect(paths.NOT_FOUND)

  if (routeIsAllowed(nextView)) {
    const querystringParams = decodeQuerystringValues(search)
    const urlParams = nextView.urlMatch?.(pathname)?.params || {}

    // Dispatch next view to be handled by the app
    window.dispatchEvent(
      new CustomEvent(AppEvents.URL_UPDATED, {
        bubbles: true,
        composed: true,
        detail: {
          nextView,
          context: {
            params: { ...querystringParams, ...urlParams },
            path: pathname,
          },
        },
      })
    )
  } else {
    redirect(redirectPath)
  }
}

/**
 * Chrome fires popstate on load, unlike Firefox and Safari which do not
 * This prevents that behavior and makes Chrome function like FF and S
 */
;(function () {
  let blockPopstateEvent = document.readyState != 'complete'
  window.addEventListener(
    'load',
    () =>
      // The timeout ensures that popstate-events will be unblocked right
      // after the load event occured, but not in the same event-loop cycle.
      setTimeout(function () {
        blockPopstateEvent = false
      }, 0),
    false
  )
  window.addEventListener(
    'popstate',
    (evt) => {
      if (blockPopstateEvent && document.readyState == 'complete') {
        evt.preventDefault()
        evt.stopImmediatePropagation()
      }
    },
    false
  )
})()

// Handle direct url routing (including initial page load)
window.addEventListener('load', () => onRouteChanged(), false)
// Handle back and forward button events
window.addEventListener('popstate', () => onRouteChanged(), false)
