const activeGlobalKeys = {
  8: true,
  16: true,
}

function processKeydown({ keyCode }) {
  if (activeGlobalKeys[keyCode] && !this.primedKeys.includes(keyCode))
    this.primedKeys.push(keyCode)

  if (this.primedKeys.length >= 2) {
    if (this.primedKeys.join('-') == '16-8') history.back()
  }
}

function processKeyup({ keyCode }) {
  if (activeGlobalKeys[keyCode])
    this.primedKeys = this.primedKeys.filter((key) => key !== keyCode)
}

export function appKeyboardMixin(SuperClass) {
  class AppKeyboardMixin extends SuperClass {
    static properties = {
      primedKeys: { type: Array },
    }

    constructor() {
      super()

      this.loading = true
      this.primedKeys = []
    }

    connectedCallback() {
      super.connectedCallback()

      window.addEventListener('keydown', processKeydown.bind(this))
      window.addEventListener('keyup', processKeyup.bind(this))
    }

    disconnectedCallback() {
      super.disconnectedCallback()

      window.addEventListener('keydown', processKeydown.bind(this))
      window.addEventListener('keyup', processKeyup.bind(this))
    }
  }

  return AppKeyboardMixin
}
