import { getSessionUser } from '../session/session'
import permissionTypes from './permission-types.js'

export function isAdmin(user) {
  if (!user) user = getSessionUser()

  return user?.kind === permissionTypes.ADMIN
}

export function isClient(user) {
  if (!user) user = getSessionUser()

  return user?.kind === permissionTypes.CLIENT
}

export function isPending({ approved }) {
  return approved === false
}

export function isApproved({ approved }) {
  return approved === true
}

export function setUserApproved(user) {
  return {
    ...user,
    approved: true,
  }
}

export function setUserPending(user) {
  return {
    ...user,
    approved: false,
  }
}
