import endpointMocks from '../../../test/mocks/endpoints/endpoints.js'
import { getUserToken } from '../session/session.js'
import { TVB_API } from './api-paths'

const DEFAULT_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

export async function get(url, settings = {}) {
  let response = await appFetch(url, { method: 'GET' })

  if (settings.parse && response.status === 200) {
    response = await response.json()
  }

  return response
}

export function post(url, body, settings = {}) {
  return appFetch(url, {
    ...settings,
    method: 'POST',
    body,
  })
}

export function del(url, settings = {}) {
  return appFetch(url, {
    ...settings,
    method: 'DELETE',
  })
}

export function put(url, body, settings = {}) {
  return appFetch(url, {
    ...settings,
    method: 'PUT',
    body,
  })
}

/**
 * @param {string} url 'url to add to root path
 * @param {Object} settings 'url to add to root path
 */
export function appFetch(
  url,
  { method = 'GET', body, overrideMocks, headers = {} }
) {
  if (process.env.USE_MOCKS && !overrideMocks) {
    // Passes in appFetch in case no mocks are available. In which case it makes real fetch request.
    return mockFetch(url, { method, body }, appFetch)
  }

  if (url.includes('undefined')) {
    return Promise.reject()
  }

  let settings = {
    method: method,
    headers: { ...DEFAULT_HEADERS, ...headers },
  }

  const includeToken =
    url.includes(process.env.API_URL) || url.includes(TVB_API)

  if (includeToken)
    settings.headers['Authorization'] = `Bearer ${getUserToken()}`

  if (body instanceof FormData) {
    delete settings.headers['Content-Type']
    settings.body = body
  } else if (body) {
    settings.body = JSON.stringify(body)
  }

  return fetch(url, settings)
}

export function mockFetch(url, { method = 'GET', body, parse = true }, next) {
  const response = endpointMocks[url]

  if (response) {
    return Promise.resolve(response)
  } else {
    return next(url, { method, body, parse, overrideMocks: true })
  }
}
