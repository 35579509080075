import { LitElement, html, css } from 'lit'
import '../design-system/icons/vb-icons-edit'
/**
 * @typedef {AppTableRow[]} AppTable
 * @typedef {AppTableCell[]} AppTableRow
 * @typedef {string|number} AppTableCell
 */

class AppTableElement extends LitElement {
  static properties = {
    data: { type: Array },
  }

  constructor() {
    super()

    this.data = []
    this.edit = false
  }

  static styles = css`
    :host {
      display: block;
    }
    table {
      border-collapse: var(--app-table-border-collapse, collapse);
      margin: var(--app-table-margin, 25px 0);
      font-size: var(--app-table-font-size, 0.9em);
      min-width: var(--app-table-min-width, 400px);
      width: var(--app-table-width, 95%);
      margin: var(--app-table-margin, auto);
      box-shadow: var(--app-table-box-shadow, 0 0 20px rgba(0, 0, 0, 0.15));
    }
    tr:first-of-type {
      font-weight: 600;
    }
    tr:nth-of-type(odd) {
      background-color: var(
        --app-table-odd-row-background-color,
        var(--sprout-opaque)
      );
      color: var(--app-table-odd-row-text-color, black);
    }
    th {
      background-color: var(
        --app-table-header-row-background-color,
        var(--dusk)
      );
      color: var(--app-table-header-row-text-color, #ffffff);
      font-weight: var(--app-table-header-row-font-weight, 600px);
      text-align: var(--app-table-header-row-text-align, left);
      padding: var(--app-table-th-padding, 12px 15px);
    }
    td {
      padding: var(--app-table-td-padding, 12px 15px);
    }
    @media only screen and (max-width: 775px) {
      :host {
        overflow-x: auto;
      }
      th {
        white-space: nowrap;
      }
    }
    .edit-row {
      width: 1%;
      text-align: center;
    }
    vb-icons-edit {
      --icon-fill: white;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    vb-icons-edit:hover {
      background-color: var(--ray);
      border-radius: 50%;
    }
  `

  _editClick() {
    this.dispatchEvent(
      new CustomEvent('editClick', {
        bubbles: true,
        composed: true,
      })
    )
  }

  render() {
    return html`
      <table>
        ${this.data.map((row, i) =>
          i === 0
            ? html`
                <tr>
                  ${row.map((cell) => html` <th>${cell}</th> `)}
                  ${this.edit
                    ? html`
                        <th class="edit-row">
                          <vb-icons-edit
                            @click=${this._editClick}
                          ></vb-icons-edit>
                        </th>
                      `
                    : ''}
                </tr>
              `
            : html`
                <tr>
                  ${row.map((cell) => html` <td>${cell}</td> `)}
                  ${this.edit ? html`<td class="edit-row"></td>` : ''}
                </tr>
              `
        )}
      </table>
    `
  }
}

customElements.define('app-table', AppTableElement)
export default AppTableElement
