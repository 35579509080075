// @ts-check

/**
 * @param {string} envVar - Environment Variable to check
 * @param {boolean} fallback - Feature flag value if envVar not set
 * @returns {boolean}
 */

export function flag(envVar, fallback) {
  if (envVar) {
    return envVar === 'true'
  } else {
    return fallback
  }
}

/**
 * @type {Object<string, boolean>}
 */
export const featureFlags = {
  development: {
    endpointHealth: true,
    FF_HIGH_YIELD_SAVINGS_SIGNUP: flag(
      process.env.FF_HIGH_YIELD_SAVINGS_SIGNUP,
      true
    ),
    FF_BENEFICIARY_SIGNATURE: flag(process.env.FF_BENEFICIARY_SIGNATURE, false),
  },
  staging: {},
  production: {
    FF_HIGH_YIELD_SAVINGS_SIGNUP: flag(
      process.env.FF_HIGH_YIELD_SAVINGS_SIGNUP,
      false
    ),
    FF_BENEFICIARY_SIGNATURE: flag(process.env.FF_BENEFICIARY_SIGNATURE, false),
  },
}[process.env.BUILD_MODE || 'development']
