// @ts-check

import { LitElement, html, css } from 'lit'

export class VbLogo extends LitElement {
  static styles = css`
    :host {
      display: block;
      height: 180px;
      width: auto;
    }

    svg {
      height: inherit;
      width: inherit;
      display: inherit;
    }
  `
  render() {
    // Stroke added to make the line between the icon and text render properly.
    // Shouldn't normally be necessary, and wasn't present in the original.

    return html`
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        stroke="currentColor"
        viewBox="36.43 20.82 543.5 141.08"
      >
        <g>
          <g>
            <path
              d="M211.81,86.11c-1.45,0-2.3,1.38-3.09,3.68l-13.03,38.56c-0.86,2.63-1.58,4.01-2.96,4.01 c-1.19,0-1.52-1.05-2.31-3.36l-13.29-39.22c-0.99-2.96-1.65-3.68-2.7-3.68c-0.79,0-0.86-0.33-0.86-0.79 c0-1.25,1.38-1.32,2.57-1.32h7.11c1.05,0,2.57,0.07,2.57,1.32c0,0.46-0.13,0.79-0.92,0.79c-0.46,0-1.45,0.26-1.45,1.77 c0,0.53,0.2,1.05,0.53,2.17l10.59,32.38l10.2-32.44c0.27-0.79,0.46-1.65,0.46-2.11c0-1.45-0.72-1.77-1.25-1.77 c-0.72,0-0.85-0.33-0.85-0.79c0-1.25,2.1-1.32,3.09-1.32h3.88c1.19,0,2.5,0.07,2.5,1.32C212.6,85.78,212.4,86.11,211.81,86.11z"
            ></path>
            <path
              d="M233.75,115.32h-18.62c0,6.25,2.11,13.42,9.47,13.42c4.28,0,6.38-1.84,8.36-4.47 c0.26-0.46,0.79-1.12,1.51-1.12c0.66,0,1.32,0.39,1.32,1.18c0,0.66-0.07,0.92-0.33,1.38c-2.04,4.08-6.58,6.65-12.04,6.65 c-8.29,0-14.21-5.73-14.21-16.45c0-11.32,5.59-17.44,14.02-17.44c8.49,0,12.96,5.72,12.96,13.23 C236.18,114.79,234.86,115.32,233.75,115.32z M223.02,101.31c-6.58,0-7.83,6.91-7.83,9.87c0,1.25,0.2,1.25,1.25,1.25h11.51 c1.91,0,2.17-0.46,2.17-2.5C230.13,106.11,228.48,101.31,223.02,101.31z"
            ></path>
            <path
              d="M256.44,103.22h-5.79v19.08c0,5.2,0.92,7.04,6.52,7.04c0.72,0,1.18,0,1.18,1.18c0,1.52-3.88,1.84-5.79,1.84 c-4.01,0-7.37-0.85-7.37-8.42v-20.73h-3.03c-0.99,0-1.45-0.39-1.45-1.12c0-0.92,0.92-1.51,1.71-1.91c3.03-1.58,3.95-2.9,4.47-6.84 c0.33-2.24,0.33-3.88,1.91-3.88c1.38,0,1.84,0.27,1.84,1.98v8.55h6.98c0.59,0,1.05,0.2,1.05,0.99 C258.68,102.69,257.3,103.22,256.44,103.22z"
            ></path>
            <path
              d="M286.01,115.32h-18.62c0,6.25,2.11,13.42,9.47,13.42c4.28,0,6.38-1.84,8.36-4.47 c0.26-0.46,0.79-1.12,1.51-1.12c0.66,0,1.32,0.39,1.32,1.18c0,0.66-0.07,0.92-0.33,1.38c-2.04,4.08-6.58,6.65-12.04,6.65 c-8.29,0-14.21-5.73-14.21-16.45c0-11.32,5.59-17.44,14.02-17.44c8.49,0,12.96,5.72,12.96,13.23 C288.45,114.79,287.13,115.32,286.01,115.32z M275.29,101.31c-6.58,0-7.83,6.91-7.83,9.87c0,1.25,0.2,1.25,1.25,1.25h11.51 c1.91,0,2.17-0.46,2.17-2.5C282.39,106.11,280.75,101.31,275.29,101.31z"
            ></path>
            <path
              d="M312.86,106.9c-1.58,0-2.04-2.9-4.87-2.9c-4.28,0-6.45,5.46-6.45,10.33v12.37c0,3.16,0.92,3.16,1.84,3.16 c0.73,0,0.86,0.53,0.86,0.86c0,1.31-1.12,1.64-2.37,1.64h-6.05c-1.05,0-2.43-0.33-2.43-1.64c0-0.33,0.06-0.86,0.79-0.86 c0.99,0,1.91,0,1.91-3.16v-20.6c0-2.57-0.46-3.23-1.71-3.23c-0.66,0-0.99-0.26-0.99-0.85c0-1.32,1.45-1.65,2.5-1.65h4.21 c1.31,0,1.51,1.05,1.51,2.5c0,0.92-0.07,2.44-0.07,3.49c2.17-4.28,6.12-6.58,9.41-6.58c3.29,0,4.34,1.25,4.34,3.03 C315.29,105.58,313.98,106.9,312.86,106.9z"
            ></path>
            <path
              d="M341.22,132.04c-2.63,0-4.61-0.26-4.61-4.28v-1.45c-2.3,3.22-5.46,6.05-10.99,6.05c-3.82,0-7.5-2.24-7.5-8.29 c0-7.3,5.26-8.55,13.42-10.66c2.3-0.59,5.07-1.58,5.07-3.35v-4.8c0-1.45-1.25-3.75-4.93-3.75c-3.68,0-5.39,1.97-6.51,4.34 c-0.66,1.31-1.25,3.62-2.76,3.62c-1.45,0-1.65-3.49-1.65-4.54c0-2.31,0.66-3.69,1.65-4.28c1.05-0.53,1.71-0.66,3.55-1.12 c2.17-0.52,4.6-0.79,6.58-0.79c4.47,0,9.54,1.05,9.54,6.84v20.27c0,2.43,0.33,3.42,1.58,3.42c0.66,0,0.86,0.39,0.86,0.85 C344.51,131.38,342.79,132.04,341.22,132.04z M336.61,114c-0.72,0.59-2.89,1.45-4.87,1.98c-4.87,1.38-7.96,2.43-7.96,7.5 c0,1.84,0.59,5.07,4.34,5.07c4.21,0,7.17-3.16,8.49-6.25V114z"
            ></path>
            <path
              d="M357.59,106.97c2.63-4.67,7.44-7.63,11.32-7.63c6.18,0,8.36,2.83,8.36,10.07v17.31 c0,3.16,0.86,3.16,1.78,3.16c0.72,0,0.92,0.53,0.92,0.86c0,1.31-1.12,1.64-2.44,1.64h-5.99c-0.92,0-2.44-0.33-2.44-1.64 c0-0.33,0.2-0.86,0.86-0.86c0.92,0,1.84,0,1.84-3.16v-14.48c0-4.08-0.13-8.68-5.26-8.68c-5.46,0-8.95,5.86-8.95,12.04v11.12 c0,3.16,0.86,3.16,1.78,3.16c0.72,0,0.92,0.53,0.92,0.86c0,1.31-1.12,1.64-2.43,1.64h-5.99c-0.99,0-2.43-0.33-2.43-1.64 c0-0.33,0.2-0.86,0.85-0.86c0.92,0,1.84,0,1.84-3.16v-20.6c0-3.09-0.79-3.23-1.84-3.23c-0.66,0-0.85-0.46-0.85-0.92 c0-1.25,1.45-1.58,2.43-1.58h4.28c1.31,0,1.51,1.05,1.51,3.03C357.66,104.93,357.66,105.91,357.59,106.97z"
            ></path>
            <path
              d="M415.48,132.36h-14.94c-1.12,0-2.5-0.06-2.5-1.31c0-0.53,0.06-0.79,0.79-0.79c0.92,0,2.1-0.46,2.1-3.42V90.98 c0-2.96-1.18-3.36-2.1-3.36c-0.73,0-0.79-0.39-0.79-0.85c0-1.25,1.38-1.32,2.5-1.32h15.2c5.26,0,14.34,0.86,14.34,9.87 c0,6.51-4.01,10.46-9.61,11.91v0.27c8.95,1.05,11.78,6.58,11.78,11.38C432.26,128.42,424.23,132.36,415.48,132.36z M413.24,88.41 c-4.41,0-6.25,0.07-6.25,2.96v15.07h5.79c8.16,0,10.92-4.87,10.92-8.95C423.71,88.81,418.31,88.41,413.24,88.41z M413.51,109.27 h-6.51v17.44c0,2.17,0.92,2.43,4.15,2.43h4.01c3.62,0,10.4-1.51,10.4-10.59C425.55,110.26,418.31,109.27,413.51,109.27z"
            ></path>
            <path
              d="M456.87,106.9c-1.58,0-2.04-2.9-4.87-2.9c-4.28,0-6.45,5.46-6.45,10.33v12.37c0,3.16,0.92,3.16,1.84,3.16 c0.73,0,0.86,0.53,0.86,0.86c0,1.31-1.12,1.64-2.37,1.64h-6.05c-1.05,0-2.43-0.33-2.43-1.64c0-0.33,0.06-0.86,0.79-0.86 c0.99,0,1.91,0,1.91-3.16v-20.6c0-2.57-0.46-3.23-1.71-3.23c-0.66,0-0.99-0.26-0.99-0.85c0-1.32,1.45-1.65,2.5-1.65h4.21 c1.31,0,1.51,1.05,1.51,2.5c0,0.92-0.07,2.44-0.07,3.49c2.17-4.28,6.12-6.58,9.41-6.58s4.34,1.25,4.34,3.03 C459.3,105.58,457.99,106.9,456.87,106.9z"
            ></path>
            <path
              d="M477.33,132.36c-9.61,0-15.07-6.91-15.07-17.11c0-11.58,7.24-16.78,14.8-16.78c9.54,0,15,6.12,15,16.98 C492.07,126.24,484.83,132.36,477.33,132.36z M477.07,101.31c-5.79,0-8.95,3.95-8.95,12.96c0,8.95,3.29,15.27,9.21,15.27 c5.66,0,8.88-4.54,8.88-13.09C486.21,106.97,482.86,101.31,477.07,101.31z"
            ></path>
            <path
              d="M520.65,132.36c-2.83,0-4.01-1.45-7.17-5.72c-3.16-4.35-6.18-9.21-7.57-10.53c-0.79,0.2-2.11,0.86-2.11,0.86 v9.21c0,3.09,0.92,3.16,1.78,3.16c0.72,0,0.92,0.53,0.92,0.86c0,1.31-1.25,1.64-2.5,1.64h-5.92c-0.99,0-2.37-0.33-2.37-1.64 c0-0.33,0.13-0.86,0.86-0.86c0.99,0,1.77,0,1.77-3.09V89.86c0-2.37-0.85-3.29-1.84-3.29c-0.66,0-0.72-0.46-0.72-0.85 c0-1.32,1.31-1.51,2.37-1.51h4.14c1.51,0,1.51,1.77,1.51,3.09v27.04c1.78-0.52,3.49-1.25,6.12-3.62c4.14-3.88,4.47-6.25,4.47-7.31 c0-0.85-0.92-1.05-1.25-1.05c-0.79,0-0.85-0.39-0.85-0.85c0-1.25,1.12-1.58,2.5-1.58h4.93c0.92,0,2.3,0,2.3,1.45 c0,0.79-1.05,1.25-2.1,2.43c-2.44,2.7-5.4,6.38-9.54,9.54c0.59,0.79,1.45,1.77,3.22,4.21c2.63,3.55,2.9,4.08,5.73,7.37 c0.92,1.05,1.71,2.1,2.96,3.16c1.25,0.99,2.17,1.38,2.17,1.84C524.47,130.98,522.95,132.36,520.65,132.36z"
            ></path>
            <path
              d="M550.15,115.32h-18.62c0,6.25,2.11,13.42,9.47,13.42c4.28,0,6.38-1.84,8.36-4.47 c0.26-0.46,0.79-1.12,1.51-1.12c0.66,0,1.32,0.39,1.32,1.18c0,0.66-0.07,0.92-0.33,1.38c-2.04,4.08-6.58,6.65-12.04,6.65 c-8.29,0-14.21-5.73-14.21-16.45c0-11.32,5.59-17.44,14.02-17.44c8.49,0,12.96,5.72,12.96,13.23 C552.59,114.79,551.27,115.32,550.15,115.32z M539.43,101.31c-6.58,0-7.83,6.91-7.83,9.87c0,1.25,0.2,1.25,1.25,1.25h11.51 c1.91,0,2.17-0.46,2.17-2.5C546.54,106.11,544.89,101.31,539.43,101.31z"
            ></path>
            <path
              d="M577,106.9c-1.58,0-2.04-2.9-4.87-2.9c-4.28,0-6.45,5.46-6.45,10.33v12.37c0,3.16,0.92,3.16,1.84,3.16 c0.73,0,0.86,0.53,0.86,0.86c0,1.31-1.12,1.64-2.37,1.64h-6.05c-1.05,0-2.43-0.33-2.43-1.64c0-0.33,0.06-0.86,0.79-0.86 c0.99,0,1.91,0,1.91-3.16v-20.6c0-2.57-0.46-3.23-1.71-3.23c-0.66,0-0.99-0.26-0.99-0.85c0-1.32,1.45-1.65,2.5-1.65h4.21 c1.31,0,1.51,1.05,1.51,2.5c0,0.92-0.07,2.44-0.07,3.49c2.17-4.28,6.12-6.58,9.41-6.58s4.34,1.25,4.34,3.03 C579.43,105.58,578.12,106.9,577,106.9z"
            ></path>
          </g>
          <g>
            <path
              d="M182.66,57.27h-2.96l-2.38,10.06c-0.24,0.96-0.38,1.72-0.38,2.31c0,1.27,0.9,1.38,2.34,1.38 c0.31,0,0.41,0.17,0.41,0.45c0,0.72-1.58,1.1-3.31,1.1c-0.62,0-2.41-0.17-2.41-2.31c0-0.59,0.17-1.45,0.31-2l2.62-10.99h-2.41 c-0.24,0-0.52-0.1-0.52-0.48c0-0.79,0.52-0.76,1.14-0.96c1.62-0.52,2.72-1.31,3.51-2.96c0.62-1.27,0.72-2.69,1.93-2.69 c0.38,0,0.69,0.04,0.69,0.38c0,0.21-0.07,0.55-0.14,0.86l-1,4.17h3.62c0.31,0,0.48,0.14,0.48,0.34 C184.21,56.61,183.72,57.27,182.66,57.27z"
            ></path>
            <path
              d="M199.05,60.75l-1.86,7.99c-0.14,0.55-0.24,1.31-0.24,1.51c0,0.48,0.07,0.79,0.65,0.79 c0.41,0,0.52,0.17,0.52,0.38c0,0.48-0.48,0.9-1.38,0.9h-2.13c-0.59,0-0.72-0.48-0.72-0.86c0-0.62,0.24-1.45,0.38-2.07l1.76-7.27 c0.28-1.14,0.48-2.34,0.48-3c0-0.83-0.24-1.62-1.45-1.62c-2.38,0-5.61,3.24-6.3,6.2l-1.27,5.51c-0.07,0.31-0.17,0.83-0.17,1.03 c0,0.72,0.34,0.79,0.65,0.79s0.38,0.21,0.38,0.38c0,0.72-0.86,0.9-1.55,0.9h-3.03c-0.65,0-1-0.28-1-0.86 c0-0.24,0.17-0.41,0.58-0.41c0.72,0,1.03-0.62,1.28-1.62l4.48-18.84c0.07-0.34,0.14-0.9,0.14-1.1c0-0.38-0.24-0.59-0.52-0.59 c-0.31,0-0.45-0.14-0.45-0.41c0-0.59,0.79-0.86,1.52-0.86h1.79c0.65,0,0.79,0.24,0.79,0.76c0,0.21-0.14,0.79-0.31,1.48l-2.24,9.54 h0.14c2.17-2.45,4.93-4.1,6.85-4.1c1.55,0,2.58,0.69,2.58,2.72C199.39,58.79,199.19,60.09,199.05,60.75z"
            ></path>
            <path
              d="M209.31,63.71c-1.48,0.21-3.72,0.34-3.72,0.34c-0.1,0.24-0.24,1.48-0.24,2.38c0,2.41,1.07,4.62,3.48,4.62 c1.72,0,3.2-1.03,4.44-2.34c0.28-0.27,0.52-0.48,0.76-0.48c0.24,0,0.65,0.14,0.65,0.62c0,0.21-0.14,0.48-0.38,0.76 c-1.52,1.93-3.48,3.17-6.78,3.17c-1.48,0-5.17-0.28-5.17-6.06c0-6.3,4.24-11.64,10.37-11.64c2.86,0,4.17,0.97,4.17,2.82 C216.89,60.3,214.51,63.02,209.31,63.71z M211.69,56.44c-3.24,0-5.23,4.31-5.75,6.37c0,0,1.79-0.1,2.89-0.24 c4.17-0.55,5.17-2.62,5.17-3.93C213.99,57.03,212.96,56.44,211.69,56.44z"
            ></path>
          </g>
        </g>
        <path
          d="M143.84,161.4c-0.78,0-1.42-0.64-1.42-1.42V22.74c0-0.78,0.64-1.42,1.42-1.42c0.78,0,1.42,0.64,1.42,1.42 v137.24C145.26,160.77,144.63,161.4,143.84,161.4z"
        ></path>
        <g>
          <path
            d="M88.3,72.65H54.49c-0.45,0-0.82,0.37-0.82,0.83l11.22,11.42c0.53,0.54,1.27,0.85,2.03,0.85h7.45 c4.4,0,7.97,3.57,7.97,7.97v7.71c0,0.75,0.3,1.47,0.82,2l11.21,11.33c0.45,0,0.82-0.37,0.82-0.83V79.56 C95.2,75.76,92.09,72.65,88.3,72.65z"
          ></path>
          <path
            d="M105.03,55.06H71.22c-0.45,0-0.82,0.37-0.82,0.83l11.22,11.42c0.53,0.54,1.27,0.85,2.03,0.85h7.45 c4.4,0,7.97,3.57,7.97,7.97v7.71c0,0.75,0.3,1.47,0.82,2l11.21,11.33c0.45,0,0.82-0.37,0.82-0.83V61.97 C111.94,58.17,108.83,55.06,105.03,55.06z"
          ></path>
          <path
            d="M71.56,90.24H37.75c-0.45,0-0.82,0.37-0.82,0.83l11.22,11.42c0.53,0.54,1.27,0.85,2.03,0.85h7.45 c4.4,0,7.97,3.57,7.97,7.97v7.71c0,0.75,0.3,1.47,0.82,2l11.21,11.33c0.45,0,0.82-0.37,0.82-0.83V97.15 C78.47,93.35,75.36,90.24,71.56,90.24z"
          ></path>
        </g>
      </svg>
    `
  }
}

customElements.define('vb-logo', VbLogo)
