/**
 * @description Sorts by date property. If no date property on value, sorts by the value itself. Can accept either a date or a date string.
 * @param {Object|string|Date} a
 * @param {Object|string|Date} b
 * @returns sort integer
 */
export const sortByDate = (a, b) => {
  if (a === null || a.date === null) return 1
  if (b === null || b.date === null) return -1

  const aTs = new Date(a.date || a).getTime()
  const bTs = new Date(b.date || b).getTime()

  return aTs - bTs
}
