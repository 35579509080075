// @ts-check
/** @typedef {import('../../js/models/router.model.js').Route} Route */

import { LitElement, html, css } from 'lit'
import { go } from '../../js/router/router'

class RouterLink extends LitElement {
  static shadowRootOptions = {
    ...LitElement.shadowRootOptions,
    delegatesFocus: true,
  }

  static properties = {
    to: { type: String },
    title: { type: String },
  }

  constructor() {
    super()

    this.to = ''
    this.title = ''
  }

  clickHandler(/** @type {Event} event */ event) {
    if (this.to) go(this.to)
    event.preventDefault()
  }

  render() {
    return html`
      <a
        href=${this.to}
        @click=${this.clickHandler}
        title=${this.title}
        part="a"
      >
        <slot></slot>
      </a>
    `
  }

  static styles = css`
    a {
      color: inherit;
      font-family: inherit;
      font-size: inherit;
      text-decoration: inherit;
    }
  `
}

customElements.define('router-link', RouterLink)
export default RouterLink
