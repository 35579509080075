// @ts-check
/**
 * @typedef {import('../models/user.model').User} User
 * @typedef {import('../models/router.model.js').Route} Route
 * @typedef {import('../models/router.model.js').ContextParams} ContextParams
 * @typedef {import('../models/router.model.js').RouteEnter} RouteEnter
 */

import AppEvents from '../app-events.js'
import { getUserById } from '../services/user.service.js'
import { toast } from '../util/toast.js'
import paths from './paths.js'
import { getUserDefaultRoute } from './router-util.js'
import './router.js'
import { go } from './router.js'
import { routes } from './routes.js'

/** @param {string} path the path to the view component within the views folder */
const componentLoader = (path) => import(`../../components/views/${path}`)

/**
 *
 * @param {HTMLElement & ContextParams} component
 * @param {Object} params
 * @returns {HTMLElement}
 */
export function appendValuesToComponent(component, params = {}) {
  Object.entries(params).forEach(([k, v]) => (component[k] = v))
  return component
}

/**
 * @param {{viewObj: Route, params: ContextParams}} arg
 * @returns {HTMLElement & ContextParams}
 * */
export function buildViewComponent({ viewObj, params }) {
  return appendValuesToComponent(
    document.createElement(viewObj.component),
    params
  )
}

// @ts-ignore
export function routerMixin(SuperClass) {
  /** @type {Router} */
  class Router extends SuperClass {
    static properties = {
      currentRoute: { type: Object },
      showNav: { type: Boolean },
    }

    constructor() {
      super()

      // @ts-ignore
      this.showNav = false
      // @ts-ignore
      this.currentRoute = null
      window.addEventListener(
        AppEvents.URL_UPDATED,
        this.urlUpdated.bind(this, this.user)
      )
    }

    /**
     * @param {User} user
     * @param {{detail: {nextView: Route, context: ContextParams}}} arg2
     */
    async urlUpdated(user, { detail }) {
      const { nextView, context } = detail

      this.showNav = !nextView.hideNavBar
      this.currentRoute = nextView

      if (!nextView.manualImport) {
        await componentLoader(nextView.componentPath).catch(() => {
          toast('This page is not currently available.')
          const userDefault = getUserDefaultRoute(routes, user)
          // In order to prevent a loop in case the user default path is the path having trouble
          const redirect = window.location.href.includes(userDefault.path)
            ? paths.LOGIN
            : userDefault.path
          go(redirect)
        })
      }

      const slot = this.shadowRoot.querySelector('slot')
      slot.innerHTML = ''
      const viewComponent = buildViewComponent({
        viewObj: nextView,
        params: context,
      })

      if (nextView.inheritedProperties) {
        nextView.inheritedProperties.forEach((prop) => {
          viewComponent[prop] = this[prop]
        })
      }

      if (context.params.userId) {
        viewComponent.user = await getUserById(context.params.userId)
      } else {
        viewComponent.user = this.user
      }

      /** @type {RouteEnter} */
      if (viewComponent.routeEnter)
        await viewComponent.routeEnter({ nextView, context })

      viewComponent.id = 'view-container'

      slot.append(viewComponent)

      document.title = `${nextView.title} - The Veteran Broker`
    }
  }

  return Router
}
