// @ts-check

/**
 * @typedef {import('../models/http.model').HttpMethod} HttpMethod
 * @typedef {import('../models/http.model').HttpError} HttpError
 */

import { getUserToken } from '../session/session'

const DEFAULT_HEADERS = {
  'Access-Control-Allow-Origin': '*',
  'Content-Type': 'application/json',
  Accept: 'application/json',
}

/**
 * @param {string} path
 * @param {HttpMethod} method
 * @param {Object} [body]
 * @returns {Promise<Response>}
 */
async function apiFetch(path, method, body) {
  const resource = `${process.env.API_URL}${path}`
  const token = getUserToken()
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        ...DEFAULT_HEADERS,
      }
    : DEFAULT_HEADERS

  const includeBody = !!(body && !['GET', 'HEAD'].includes(method))

  const init = includeBody
    ? { method, headers, body: JSON.stringify(body) }
    : { method, headers }

  const res = await fetch(resource, init)

  if (res.ok) {
    // returning response allows caller to parse body as needed
    return res
  } else {
    throw /** @type {HttpError} */ {
      message: (await res.json()).message || res.statusText,
      res,
    }
  }
}

/** USERS */
/**
 * @typedef {import('../models/user.model').User} User
 */

/**
 * @param {string} userId
 * @returns {Promise<User>}
 */
export async function getUser(userId) {
  return await apiFetch(`/v1/user?userid=${userId}`, 'GET').then((res) =>
    res.json()
  )
}

/**
 * @param {User} user
 * @returns {Promise<User>}
 */
export function updateUser(user) {
  return apiFetch(
    `/v1/users/admin/user?userid=${user.userId}`,
    'PUT',
    user
  ).then((res) => res.json())
}

/** ACCOUNTS */
/**
 * @typedef {import('../models/accounts.model').Account} Account
 * @typedef {import('../models/accounts.model').AccountUpdateBody} AccountUpdateBody
 * @typedef {import('../models/accounts.model').AccountCreateBody} AccountCreateBody
 */

/**
 * @param {string} userId
 * @returns {Promise<Account[]>}}
 */
export async function getAccountsByUserId(userId) {
  return await apiFetch(`/v1/users/accounts?userid=${userId}`, 'GET').then(
    (res) => res.json()
  )
}

/**
 * @param {AccountCreateBody} opts
 * @returns {Promise<Account>}
 */
export async function createAccount(opts) {
  return await apiFetch('/v1/accounts/create', 'POST', opts).then((res) =>
    res.json()
  )
}

/**
 * @param {string} id - the database id of the account
 * @param {AccountUpdateBody} accountUpdateBody
 * @returns {Promise<Account>}
 */
export async function updateAccount(id, accountUpdateBody) {
  return await apiFetch(
    `/v1/accounts/update?id=${id}`,
    'PUT',
    accountUpdateBody
  ).then((res) => res.json())
}

/** @param {{userId: string, requestedAmount: number}} requestBody - id of the user to sign up */
export function sendHighYieldSignupEmail(requestBody) {
  return apiFetch('/v1/accounts/signup', 'POST', requestBody)
}

/** CRYPTO PRICES */
/** @typedef {import('../models/crypto.model').CryptoPricesResponse} CryptoPricesResponse */

/**
 * @description get the top 400 market cap from coin market cap
 * @returns {Promise<CryptoPricesResponse>}
 */
export async function getCurrentCryptoPrices() {
  return await apiFetch(`/v1/crypto/prices`, 'GET').then((res) => res.json())
}
