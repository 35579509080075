export const stripComma_ = (st = '') => st.replace(/,/g, '')
export const stripDollar_ = (st = '') => st.replace(/\$/g, '')

/**
 * @param {string} st
 * @returns {number}
 * */
export const displayToDollars = (st) =>
  parseFloat(stripComma_(stripDollar_(st)))
export const displayToCents = (st) =>
  parseFloat(stripComma_(stripDollar_(st))) * 100

/**
 * @param {number} amount
 * @returns {string} the display amount E.g: $1.00
 */
export function dollarsToDisplay(amount) {
  amount = amount ?? 0

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return formatter.format(amount)
}

/**
 * @param {number} amount integer amount in dollars E.g: 1
 * @returns {string} the dollar display amount E.g: $1.00
 */
export function assetPriceToDollarDisplay(amount) {
  amount = amount ?? 0

  const largeFormat = `${amount.toFixed(15)}`
  const [dollars, cents] = largeFormat.split('.')

  if (+dollars <= 0) {
    if (cents <= 0) {
      return '$0.00'
    }

    return `$${amount.toFixed(15)}`.replace(/0*$/, '')
  }

  return dollarsToDisplay(amount)
}

export function centsToDisplay(cents = 0) {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formatter.format(cents / 100)
}
