/**
 * @param {string} str SOME_SNAKE_STRING
 * @returns {string} some-dash-string
 */
export function convertSnakeToDash(str = '') {
  return str.replace(/_/g, '-').toLowerCase()
}

/**
 * @param {string} str SOME_SNAKE_STRING
 * @returns {string} Some snake string
 */
export function convertSnakeToSentence(str = '') {
  const first = str[0].toUpperCase()
  const rest = str.slice(1).toLowerCase()

  return (first + rest).replaceAll('_', ' ')
}
