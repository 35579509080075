// @ts-check

import AppEvents from '../app-events'
import { getUserById } from '../services/user.service'

export const ViewEvents = {
  REFRESH_VIEW_USER: 'update-view-user',
}

/**
 * @typedef {import('lit').LitElement} LitElement
 * @typedef {import('../models/user.model').User} User
 * @typedef {import('../models/router.model').ContextParams} ContextParams
 */

/**
 * @template [ T = {} ]
 * @typedef { new (...args: any[]) => T } Constructor<T>
 */

/**
 * @typedef {Object} ViewContainerMixinInterface
 * @property {User} user
 * @property {ContextParams} params
 * @property {string} path
 */

/**
 * @template { Constructor<LitElement> } T
 * @param {T} superClass
 * @returns {Constructor<ViewContainerMixinInterface> & T}
 */
export const ViewContainerMixin = (superClass) =>
  class extends superClass {
    static properties = {
      user: { type: Object },
      params: { attribute: false },
      path: { attribute: false },
    }

    /** @param {any[]} args */
    /* eslint-disable no-unused-vars */
    constructor(...args) {
      super()

      this.user = null
      this.params = null
      this.path = null
    }

    async connectedCallback() {
      super.connectedCallback()

      this.addEventListener(
        ViewEvents.REFRESH_VIEW_USER,
        this.fetchUpdatedUser.bind(this)
      )
    }

    disconnectedCallback() {
      super.disconnectedCallback()

      this.removeEventListener(
        ViewEvents.REFRESH_VIEW_USER,
        this.fetchUpdatedUser.bind(this)
      )
    }

    async fetchUpdatedUser(/** @type {CustomEvent} */ e) {
      const user = await getUserById(this.user.userId)
      this.dispatchEvent(
        new CustomEvent(AppEvents.USER_UPDATED, {
          bubbles: true,
          composed: true,
          detail: { user },
        })
      )
    }
  }
