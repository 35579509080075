import { LitElement, html, css } from 'lit'

import '../../../design-system/app-table'
import '../../../design-system/logos/vb-logo'

import { getSimpleDate, startOfMonthSimpleDate } from '../../../js/util/date'

import '../../shared/reports/report-header'

class ClientReport extends LitElement {
  static properties = {
    user: { type: Object },
    report: { type: Object },
  }

  render() {
    const format = this.report.displayFormatter || ((x) => x)
    return html`
      <report-header .user=${this.user}></report-header>
      <div class="table-heading">
        <h3 class="report-name">${this.report.reportName}</h3>
        <p class="date-range">
          ${startOfMonthSimpleDate()} &mdash; ${getSimpleDate()}
        </p>
      </div>
      <app-table .data=${format(this.report.tableData)} />
    `
  }

  static styles = css`
    :host {
      box-sizing: border-box;
      display: block;
      width: 8.5in;
      height: 11in;
      font-family: var(--primary-font);
      font-size: 12pt;
      border: 1px solid darkgray;
      padding: 0.5in;
    }

    report-header {
      border-bottom: 2px solid var(--accent);
      padding-bottom: 0.3rem;
    }

    h3 {
      font-family: var(--headline-font);
      margin: 0;
      text-align: right;
    }

    p {
      margin: 0;
    }

    .table-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 2rem 0 1rem 0;
    }

    .report-name {
      color: var(--secondary);
    }

    .date-range {
      font-size: 10pt;
    }

    app-table {
      background-color: #f8f8f8;
      --app-table-width: 100%;
      --app-table-margin: 0;
      --app-table-box-shadow: none;
      --app-table-font-size: 10pt;
      --app-table-header-row-border-bottom: 1px solid var(--dusk);
      --app-table-header-row-background-color: var(--cloud);
      --app-table-header-row-text-color: var(--primary);
      --app-table-odd-row-background-color: #eee;
    }

    @media print {
      :host {
        width: 100%;
        height: auto;
        min-height: auto;
        padding: 0;
        background: #fff;
        border: none;
        color: #000;
      }
    }
  `
}
customElements.define('client-report', ClientReport)
export default ClientReport
