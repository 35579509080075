// @ts-check
/**
 * @typedef {import('../models/router.model.js').ContextParams} ContextParams
 * @typedef {import('../models/router.model.js').Route} Route
 * @typedef {import('../models/user.model').SessionUser} SessionUser
 * @typedef {import('../models/user.model').User} User
 */

import { getSessionUser } from '../session/session'
import permissionTypes from '../util/permission-types'

/**
 * @param {string} path
 * @param {Object<string, *>} params
 * @returns {string} the path with params appended
 * */
export function appendParamsObjToPath(path, params) {
  if (!Object.entries(params || {}).length) return path

  return (
    path +
    (path.includes('?') ? '&' : '?') +
    new URLSearchParams(params).toString()
  )
}

/**
 * @param {Route[]} routes the routes to search
 * @param {string} pathname the id of the route to find e.g. "/someroute"
 * @returns {Route}
 * */
export function getRouteByPathname(routes = [], pathname) {
  return routes.find(({ pathRegex }) =>
    pathRegex?.exec(pathname.replace(/\?.*/, ''))
  )
}

/**
 * @param {Route[]} routes the routes to search
 * @param {User|SessionUser} user the id of the route to find e.g. "/someroute"
 * @returns {Route} the default route object for the user
 * */
export function getUserDefaultRoute(routes, user) {
  const userType = user.kind || permissionTypes.CLIENT
  return routes.find(({ defaultFor }) => defaultFor === userType)
}

/** @param {string} querystring */
export function decodeQuerystringValues(querystring) {
  const paramMap = new URLSearchParams(querystring.replace('?', ''))
  const params = Array.from(paramMap.entries()).reduce((params, [key, val]) => {
    return {
      ...params,
      [key]: _normalizeQsValue(val),
    }
  }, {})

  return params
}

/**
 * @description ensure boolean/number values instead of boolean/number string
 * @param {string} param
 * @returns {number|string|boolean}
 */
export function _normalizeQsValue(param) {
  if (param.toLowerCase() === 'true') return true
  if (param.toLowerCase() === 'false') return false

  // @ts-ignore
  if (!isNaN(param)) return +param

  return param
}

/**
 * @param {Route} nextView
 * @returns {boolean} whether or not the route is allowed
 * */
export function routeIsAllowed(nextView) {
  if (nextView.isPublic) return true
  // User must be logged in
  if (!getSessionUser()?.userId) return false

  const noPermissions = !nextView.permissions || !nextView.permissions.length

  return (
    noPermissions ||
    nextView.permissions.some((check) => {
      try {
        return check()
      } catch (err) {
        return false
      }
    })
  )
}
