// @ts-check

/**
 * @typedef {import('../models/user.model').User} User
 * @typedef {import('../models/user.model').SessionUser} SessionUser
 */

/**
 * @type {{
 *  USER: 'user',
 *  USER_TOKEN: 'userToken',
 *  SESSION_EXPIRATION: 'sessionExpiration',
 * }}
 */
const sessionVariables = {
  USER: 'user',
  USER_TOKEN: 'userToken',
  SESSION_EXPIRATION: 'sessionExpiration',
}

/**
 * @param {Object} data - Data to set in session
 * @param {User} data.user - User to set in session
 * @param {string} data.expiry - User token expiry to set in session
 */
export function setSessionData({ expiry, user }) {
  setSessionExpiration(expiry)
  setSessionUser(user)
}

export function setSessionUser(/** @type {User} */ user) {
  return window.sessionStorage.setItem(
    sessionVariables.USER,
    JSON.stringify(userToSessionUser(user))
  )
}

/**
 * @returns {SessionUser|null}
 */
export function getSessionUser() {
  return JSON.parse(window.sessionStorage.getItem(sessionVariables.USER))
}

export function deleteSessionUser() {
  sessionStorage.removeItem(sessionVariables.USER)
  sessionStorage.removeItem(sessionVariables.USER_TOKEN)
}

export function isUserLoggedIn() {
  const user = getSessionUser()

  return !!(user && user.userId)
}

/**
 * @param {string} token - Session token to set in session
 */
export function setUserToken(token) {
  return window.sessionStorage.setItem(sessionVariables.USER_TOKEN, token)
}

export function getUserToken() {
  return window.sessionStorage.getItem(sessionVariables.USER_TOKEN)
}

/**
 * @param {string} expiry the UTC timestamp example: YYYY-MM-DDT19:00:00.000000Z
 */
export function setSessionExpiration(expiry) {
  return window.sessionStorage.setItem(
    sessionVariables.SESSION_EXPIRATION,
    expiry
  )
}

/**
 * @returns {string} the UTC expiration timestamp example: YYYY-MM-DDT19:00:00.000000Z
 */
export function getSessionExpiration() {
  return window.sessionStorage.getItem(sessionVariables.SESSION_EXPIRATION)
}

/**
 * @param {User} user
 * @returns {SessionUser}
 */
export function userToSessionUser({ userId, kind }) {
  return { userId, kind }
}
