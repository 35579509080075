import { dollarsToDisplay } from '../../../js/util/reports/report-util.js'

/** @typedef {import('../../../js/models/accounts.model.js').AccountV2} AccountV2 */
/** @typedef {import('../../../js/models/crypto.model.js').CryptoPrice} Price */

/**
 * @param {AccountV2[]} accounts
 * @returns {string} the display ammount of the portfolio balance — e.g. $1,000.00
 * */
export function calculatePortfolioBalance(accounts = []) {
  if (!accounts) return '$0.00'

  const cryptoAssetTotals = accounts
    .filter(isCryptoAssetAccount)
    .flatMap(({ cryptoAssets }) => cryptoAssets || [])
    .reduce((sum, { quantity, price }) => sum + quantity * price, 0)

  const highYieldTotals = accounts
    .filter(({ accountType }) => accountType === 'high_yield_savings_account')
    .flatMap(({ transactions }) => transactions || [])
    .reduce((sum, { debit, credit }) => sum + credit - debit, 0)

  return dollarsToDisplay(highYieldTotals + cryptoAssetTotals)
}

/**
 *
 * @param {AccountV2} account
 * @returns {Boolean}
 */
export function isCryptoAssetAccount(account) {
  return account.accountType === 'crypto_assets_account'
}

/**
 *
 * @param {AccountV2} account
 * @returns {Boolean}
 */
export function isHighYieldAccount(account) {
  return account.accountType === 'high_yield_savings_account'
}

/**
 * Updates crypto assets with new prices
 * @param {AccountV2[]} accounts - Array of AccountV2 objects
 * @param {{prices: Price[], manualPrices: Price[]}} prices - Array of price updates in the form {symbol: 'BTC', price: 23000}
 * @returns {AccountV2[]} - Array of AccountV2 objects with updated crypto asset prices
 */
export function updateCryptoAssetPrices(accounts, prices) {
  const priceLookup = prices.prices.reduce(
    (/** @type {Object<string, *>} */ lookup, update) => {
      lookup[update.symbol] = update.price
      return lookup
    },
    {}
  )

  if (prices.manualPrices?.length) {
    prices.manualPrices.forEach((update) => {
      priceLookup[update.symbol] = update.price
    })
  }

  return accounts.map((account) => {
    if (isCryptoAssetAccount(account)) {
      account.cryptoAssets = account.cryptoAssets.map((cryptoAsset) => {
        cryptoAsset.price = priceLookup[cryptoAsset.symbol] ?? null
        return cryptoAsset
      })
    }

    return account
  })
}
