// @ts-check
import { DEFAULT_API } from './api-paths.js'
import { get, post } from './app-fetch.js'

/** @typedef {import('../models/accounts.model').AccountV2} AccountV2 */
/** @typedef {import('../models/accounts.model').AccountV2Create} AccountV2Create */
/** @typedef {import('../models/accounts.model').AccountUpdateBody} AccountUpdateBody */

/**
 * @param {string} accountId
 * @returns {Promise<AccountV2>}
 */
export function getAccount(accountId) {
  return get(`${DEFAULT_API}/v2/accounts?accountid=${accountId}`, {
    parse: true,
  })
}

/**
 * @param {string} userId
 * @returns {Promise<AccountV2[]>}
 */
export function getUserAccounts(userId) {
  return get(`${DEFAULT_API}/v2/users/accounts?userid=${userId}`, {
    parse: true,
  })
}

/**
 * @param {AccountV2Create} account
 * @returns {Promise<string>}
 */
export function createAccount(account) {
  return post(`${DEFAULT_API}/v2/users/accounts/create`, account)
}

/**
 * @param {AccountV2} accountUpdate
 * @returns {Promise<AccountV2>}
 */
export function adminUpdateAccount(accountUpdate) {
  return post(
    `${DEFAULT_API}/v2/users/accounts/update?accountid=${accountUpdate.id}`,
    accountUpdate
  )
}

/**
 * @param {string} accountId
 * @returns {Promise<*>}
 * */
export function deleteAccount(accountId) {
  return post(`${DEFAULT_API}/v2/users/accounts/delete?accountid=${accountId}`)
}
