// @ts-check
import { DEFAULT_API } from './api-paths.js'
import { get, put, post } from './app-fetch.js'

/** @param {string} userId */
export function getTransactionsReport(userId) {
  return get(`${DEFAULT_API}/v1/users/transaction-report?userid=${userId}`, {
    parse: true,
  })
}

// @ts-ignore
export function updateTransactionsReport(userId, transactionItems) {
  return put(`${DEFAULT_API}/v1/users/transaction-report/update`, {
    transactionsReportId: '',
    userId,
    transactionsReport: { transactionItems },
  })
}

// @ts-ignore
export function createTransactionsReport(userId, transactionItems) {
  return post(`${DEFAULT_API}/v1/users/transaction-report/report`, {
    transactionsReportId: '',
    userId,
    transactionsReport: { transactionItems },
  })
}
